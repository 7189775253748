<template>
	<div class="col-12 col-xl-12 report-text" :id="question.id">
	<div class="card card-custom gutter-b card-stretch">
		<div class="card-header border-0 pt-5">
			<div class="card-title">
				<div class="card-label">
					<div class="font-weight-bolder">{{ question.name }}</div>
					<div class="font-size-sm text-muted mt-2">{{ $t('GENERAL.General.subscriber') }}: {{ question.amount }}</div>
				</div>
			</div>
			<div class="card-toolbar">
				<a href="#" class="btn btn-sm btn-icon" v-on:click.prevent="showCloudModal">
					<i class="fas fa-cloud"></i>
				</a>
			</div>
		</div>
		<div class="card-body d-flex flex-column px-0 align-items-center" v-if="!chartFilled">
					<span class="mx-auto my-auto position-absolute empty-chart">
						<i class="fas fa-table"></i>
					</span>
			<span class="mx-auto my-auto position-absolute empty-chart-text">{{ $t('VIEWS.Report.noData') }}</span>
		</div>
		<div class="card-body d-flex flex-column px-0" v-if="chartFilled">
			<div class="px-3 prinor-table">
				<b-table ref="textTable" :data="textList" :paginated="true" :per-page="5" default-sort="Text" :default-sort-direction="'asc'" :sort-icon="'caret-up'" @dblclick="rowClicked">
					<b-table-column field="value" :label="$t('VIEWS.Report.Text.text')" v-slot="props" :sortable="true" :searchable="true" :cell-class="'d-grid'">
						<template>
							<span class="truncate">{{ props.row.value }}</span>
						</template>
					</b-table-column>
					<b-table-column field="semantic" :label="$t('VIEWS.Report.Text.textAnalysis')" :sortable="true" v-slot="props" :width="'150px'">
						<template>
							<span v-if="typeof props.row.semantic === 'undefined'"></span>
							<span v-else-if="props.row.semantic <= -0.1">
										<i class="far fa-frown text-danger"></i>
									</span>
							<span v-else-if="props.row.semantic >= 0.1">
										<i class="far fa-smile text-success"></i>
									</span>
							<span v-else>
										<i class="far fa-meh"></i>
									</span>
						</template>
					</b-table-column>
					<b-table-column field="nps" :label="$t('VIEWS.Report.Text.recommendation')" :sortable="true" v-slot="props" :width="'150px'" v-if="npsactive">
						<template>
							<span v-if="typeof props.row.nps === 'undefined'"></span>
							<span v-else-if="props.row.nps < 7">
										<i class="far fa-frown text-danger"></i>
										({{ props.row.nps }})
									</span>
							<span v-else-if="props.row.nps > 6 && props.row.nps < 9">
										<i class="far fa-meh text-warning"></i>
										({{ props.row.nps }})
									</span>
							<span v-else>
										<i class="far fa-smile text-success"></i>
										({{ props.row.nps }})
									</span>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</div>
	</div>
	<modal :name="'text-details' + this._uid" :width="1200" :height="modalHeight" :shiftY="0.1" :adaptive="true" scrollable>
		<div class="card card-custom" style="height: inherit;">
			<div class="card-header card-header-tabs-line">
				<div class="card-title w-100 d-flex justify-content-between">
					<h3 class="card-label">{{ $t('VIEWS.Report.Text.title') }}</h3>
				</div>
			</div>
			<div class="card-body">
				<div class="row h-100">
					<div class="col-12">
						<div class="form-group h-100">
							<textarea class="form-control h-100 resize-none" readonly="readonly" v-model="selectedText"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer d-flex justify-content-between">
				<button type="button" v-on:click.prevent="hideTextModal" class="btn btn-outline-danger">
					{{ $t('GENERAL.Buttons.close') }}
				</button>
				<div>
					<button type="button" v-on:click.prevent="previousText" class="btn btn-success" :disabled="selectedIndex === 0"><i class="fas fa-angle-left"></i></button>
					<button type="button" v-on:click.prevent="nextText" class="btn btn-success" :disabled="this.$props.question.texts.length - 1 <= selectedIndex"><i class="fas fa-angle-right"></i></button>
				</div>
			</div>
		</div>
	</modal>
	<modal :name="'text-cloud' + this._uid" :width="700" :height="wordCloudHeight" :shiftY="0.1" :adaptive="true" @opened="createCloud" :resizable="true">
		<div class="card card-custom" style="height: inherit;">
			<div class="card-header card-header-tabs-line">
				<div class="card-title w-100 d-flex justify-content-between">
					<h3 class="card-label">{{ $t('VIEWS.Report.Text.wordCloud') }}</h3>
				</div>
			</div>
			<div class="card-body">
				<div class="row h-100">
					<div class="col-12">
						<div class="hello mb-2" ref="chartdiv"></div>
					</div>
				</div>
			</div>
			<div class="card-footer d-flex justify-content-between">
				<button type="button" v-on:click.prevent="hideCloudModal" class="btn btn-outline-danger">
					{{ $t('GENERAL.Buttons.close') }}
				</button>
			</div>
		</div>
	</modal>
</div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4wordcloud from '@amcharts/amcharts4/plugins/wordCloud';

export default {
	name: 'ReportText',
	props: {
		question: Object,
		npsactive: Boolean,
	},
	data: function() {
		return {
			chartFilled: true,
			columns: [
				{
					field: 'Text',
					label: 'Text',
					sortable: true,
					searchable: true,
				},
			],
			selectedText: '',
			selectedIndex: 0,
			textVisible: false,
			wordCloudText: '',
			textList: [],
			subscriber: 0,
		};
	},
	mounted: function() {
		am4core.addLicense('CH246099470');
		// am4core.options.onlyShowOnViewport = true;
		this.processData();

		window.addEventListener('keyup', this.keyUpHandler);
	},
	computed: {
		modalHeight() {
			return window.screen.height * 0.9 - 100;
		},
		wordCloudHeight() {
			let calcHeight = window.screen.height * 0.6 - 100;
			return calcHeight > 500 ? calcHeight : 500;
		}
	},
	methods: {
		textClass: function(text){
			if (text != null && text !== '' && text !== ' ') {
				return 'd-flex';
			} else {
				return '';
			}
		},
		processData: function() {
			if (this.$props.question.amount > 0) {
				this.textList = this.$props.question.texts;
				this.textList.forEach(ans => {
					if (ans.words != null) {
						this.wordCloudText += ' ' + ans.words;
					}
				});
			} else {
				this.chartFilled = false;
			}
		},
		rowClicked: function(row) {
			this.selectedIndex = this.$refs.textTable.newData.indexOf(row);
			this.selectedText = row.value;
			this.textVisible = true;
			this.$modal.show('text-details' + this._uid);
		},
		previousText: function() {
			if (this.selectedIndex !== 0) {
				this.selectedIndex--;
				this.selectedText = this.$refs.textTable.newData[this.selectedIndex].value;
			}
		},
		nextText: function() {
			if (this.$props.question.texts.length - 1 > this.selectedIndex) {
				this.selectedIndex++;
				this.selectedText = this.$refs.textTable.newData[this.selectedIndex].value;
			}
		},
		keyUpHandler: function(e) {
			if (this.textVisible === true) {
				if (e.keyCode === 37) {
					this.previousText();
				} else if (e.keyCode === 39) {
					this.nextText();
				}
			}
		},
		hideTextModal: function() {
			this.textVisible = false;
			this.$modal.hide('text-details' + this._uid);
		},
		showCloudModal: function() {
			this.$modal.show('text-cloud' + this._uid);
		},
		createCloud: function() {
			let chart = am4core.create(this.$refs.chartdiv, am4wordcloud.WordCloud);
			let series = chart.series.push(new am4wordcloud.WordCloudSeries());

			series.accuracy = 4;
			series.step = 15;
			series.rotationThreshold = 0.7;
			series.maxCount = 200;
			series.minWordLength = 2;
			series.labels.template.tooltipText = '{word}: {value}';
			series.fontFamily = 'Courier New';
			series.maxFontSize = am4core.percent(30);
			series.text = this.wordCloudText;
		},
		hideCloudModal: function() {
			this.$modal.hide('text-cloud' + this._uid);
		},
	},
	watch: {
		question: function(newQuestion) {
			this.$props.question = newQuestion;
			if (this.chart != null) {
				this.processData();
			}
		},
	},
};
</script>

<style scoped>
.empty-chart {
	top: calc(50% - 7.5rem);
}

.empty-chart > i {
	font-size: 15rem;
	color: #e0e0e0;
}

.empty-chart-text {
	top: calc(50% - 1.5rem);
	font-size: 3rem;
	text-align: center;
}

.card-body {
	min-height: 20rem;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.hello {
	width: 100%;
	height: 100%;
}
</style>
<style>
.report-text .prinor-table table {
	table-layout: fixed !important;
}
</style>
