<template>
	<div class="col-xl-4 my-2">
		<div class="card card-custom">
			<div class="card-body py-0 px-0">
				<div class="row">
					<div class="col-6 text-center py-8 border-regression align-items-center d-flex justify-content-center">
						<h5 class="my-0">{{ title }}</h5>
					</div>
					<div :class="textColor" class="col-6 text-center align-items-center d-flex justify-content-center">
						<h2 class="my-0 font-weight-bolder">{{ text }}</h2>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RegressionReport',
	props: {
		title: String,
		value: Number,
		effect: Number,
	},
	computed: {
		textColor() {
			// if (this.value >= 95) {
			//     return 'text-success';
			// }
			// else if (this.value < 95 && this.value >= 50) {
			//     return 'text-warning';
			// }
			// else {
			//     return 'text-danger';
			// }
			if (this.effect < 0.3) {
				return 'text-danger';
			} else if (this.effect >= 0.3 && this.effect < 0.5) {
				return 'text-warning';
			} else {
				return 'text-success';
			}
		},
		text() {
			if (this.effect < 0.3) {
				return this.$t('VIEWS.Report.Regression.low');
			} else if (this.effect >= 0.3 && this.effect < 0.5) {
				return this.$t('VIEWS.Report.Regression.medium');
			} else {
				return this.$t('VIEWS.Report.Regression.high');
			}
		},
	},
	mounted() {},
	beforeDestroy() {},
};
</script>

<style scoped>
.border-regression {
	border-right: 1px solid darkgrey;
}
</style>
