<template>
	<div class="row">
		<div class="col-12 mb-5 prinor-filter">
			<div class="p-3">
				<div class="row">
					<div class="col-12 filter-bar" v-on:click.prevent="toggleFilter">
						<div class="filter-title d-flex float-left h-100 align-items-center">{{ $t('VIEWS.Report.Filter.filter') }} <i class="fas fa-angle-down ml-2"></i></div>
						<div class="filter-tool d-flex mr-2">
							<button type="button" class="btn btn-outline-warning mr-3" v-on:click.stop.prevent="resetFilter">{{ $t('VIEWS.Report.Filter.resetFilter') }}</button>
							<button type="button" class="btn btn-outline-success" v-on:click.stop.prevent="processFilter">{{ $t('VIEWS.Report.Filter.applyFilter') }}</button>
						</div>
					</div>
				</div>

				<!--				<transition name="custom-classes-transition" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">-->
				<transition>
					<div v-if="filterVisible && report != null" class="row">
						<div class="col-md-12 mb-2">
							<hr />
						</div>

						<div class="col-12 col-md-4 mb-5" v-if="report.can_view_all === true && report.sub_urls != null && report.sub_urls.length > 1">
							<label>{{ $t('VIEWS.Report.Filter.companyUrls') }}</label>
							<multiselect v-model="selectedCompanies" :options="companies" :multiple="true" label="label" track-by="value"
							             :placeholder="$t('VIEWS.Report.Filter.Multiselect.placeholder')"
							             :select-label="$t('VIEWS.Report.Filter.Multiselect.select')"
							             :selected-label="$t('VIEWS.Report.Filter.Multiselect.selected')"
							             :deselect-label="$t('VIEWS.Report.Filter.Multiselect.deselect')"></multiselect>
						</div>

						<div class="col-12 col-md-4 mb-5" v-if="isMoreThanOneDay && 'date' in filter">
							<vc-date-picker v-model="filter['date']" is-range locale="de-DE" :min-date="filter['date'].start" :max-date="filter['date'].end">
								<template v-slot="{ inputValue, inputEvents }">
									<div class="flex justify-center items-center">
										<div class="d-inline-block form-group">
											<label>##Zeitraum von</label>
											<b-form-input :value="inputValue.start" v-on="inputEvents.start"></b-form-input>
										</div>
										<div class="d-inline-block mx-2">
											<i class="fas fa-arrow-right"></i>
										</div>
										<div class="d-inline-block">
											<label>##Zeitraum bis</label>
											<b-form-input :value="inputValue.end" v-on="inputEvents.end"></b-form-input>
										</div>
									</div>
								</template>
							</vc-date-picker>
						</div>

						<div class="col-12 col-md-4 mb-5" v-if="report && report.languages && report.languages.length > 1">
							<label>{{ $t('VIEWS.Report.Filter.companyUrls') }}</label>
							<multiselect v-model="selectedLanguages" :options="languages" :multiple="true" label="label" track-by="value"
							             :placeholder="$t('VIEWS.Report.Filter.Multiselect.placeholder')"
							             :select-label="$t('VIEWS.Report.Filter.Multiselect.select')"
							             :selected-label="$t('VIEWS.Report.Filter.Multiselect.selected')"
							             :deselect-label="$t('VIEWS.Report.Filter.Multiselect.deselect')"></multiselect>
						</div>

						<template v-for="(t, index) in report.topics">
							<div class="col-md-12" :class="index > 0 ? 'mb-2 mt-3' : 'mb-2'" v-if="showTopic(t.questions)" :key="t.id">
								<span class="filter-topic">{{ t.name }}</span>
							</div>

							<template v-for="q in t.questions">
								<div class="col-4 form-group" v-if="q.type === 'radio' || q.type === 'checkbox'" :key="'f' + q.id">
									<label>{{ q.name }}</label>
									<div class="checkbox-list mb-2" v-for="a in q.answers" :key="'fa' + a.id">
										<label class="checkbox">
											<input type="checkbox" :value="a.id" v-model="filter[q.id]" />
											<span></span>{{ a.name }}
										</label>
									</div>
								</div>
								<div class="col-4 form-group" v-if="q.type === 'nps'" :key="'f' + q.id">
									<label>{{ q.name }}</label>
									<vue-slider class="ml-2 mr-5" v-model="filter[q.id]" :min-range="0" :max-range="10" :enable-cross="false" :min="0" :max="10" :tooltip-placement="'bottom'"></vue-slider>
								</div>
							</template>
						</template>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReportFilter',
	props: {
		report: Object,
	},
	data() {
		return {
			filterVisible: false,
			filter: {},
			companies: [],
			languages: [],
			selectedCompanies: [],
			selectedLanguages: [],
		};
	},
	mounted() {
		this.buildFilter();
	},
	computed: {
		isMoreThanOneDay: function() {
			if (this.report && this.report.daily != null) {
				let start = new Date(this.report.daily.started_at);
				let today = new Date();
				let days = Math.ceil(Math.abs(today - start) / (1000 * 60 * 60 * 24));

				return (days > 1);
			}

			return false;
		},
	},
	methods: {
		toggleFilter() {
			this.filterVisible = !this.filterVisible;
		},
		processFilter() {
			this.$parent.$emit('trigger-filter', this.filter);
			this.filterVisible = false;
		},
		resetFilter() {
			this.buildFilter();
			this.$parent.$emit('trigger-filter', this.filter);
			this.filterVisible = false;
		},
		buildFilter() {
			let that = this;
			this.filter['company'] = [];
			this.filter['languages'] = [];
			if (this.report && this.report.daily != null)
				this.filter['date'] = { start: new Date(this.report.daily.started_at), end: new Date() };

			if (this.$props.report != null) {
				this.companies = [];
				this.$props.report.sub_urls.forEach(url => {
					if (url.company != null) {
						this.companies.push({ label: url.company.name + ': (' + url.url + ')', value: url.url });
					}
				});

				this.languages = [];
				this.report.languages.forEach(lang => {
					this.languages.push({ label: lang, value: lang })
				});

				this.$props.report.topics.forEach(t => {
					t.questions.forEach(q => {
						if (q.type === 'nps') {
							that.filter[q.id] = [0, 10];
						} else {
							that.filter[q.id] = [];
						}
					});
				});
			}
		},
		showTopic(questions) {
			if (questions) {
				if (questions.length > 0) {
					let filt = questions.filter(function(value) {
						return value.type === 'radio' || value.type === 'checkbox' || value.type === 'nps';
					});
					if (filt.length > 0) {
						return true;
					}
				}
			}

			return false;
		},
	},
	watch: {
		report: function(newReport) {
			this.$props.report = newReport;
			this.buildFilter();
		},
		selectedCompanies: function(companies) {
			this.filter['company'] = [];
			if (companies != null && companies.length > 0) {
				companies.forEach(company => {
					this.filter['company'].push(company.value);
				});
			}
		},
		selectedLanguages: function(languages) {
			this.filter['languages'] = [];
			if (languages != null && languages.length > 0) {
				languages.forEach(language => {
					this.filter['languages'].push(language.value);
				});
			}
		}
	}
};
</script>

<style scoped>
@import "~flatpickr/dist/flatpickr.min.css";
div.prinor-filter > div {
	background-color: #fff;
	border-radius: 0.42rem;
}
div.prinor-filter .filter-bar {
	cursor: pointer;
}
div.prinor-filter .filter-title {
	font-size: 1.2rem;
}
div.prinor-filter .filter-title > i {
	font-size: 1.7rem;
}
div.prinor-filter .filter-tool {
	float: right;
}
div.prinor-filter .filter-topic {
	font-size: 1.2rem;
}
</style>