<template>
	<div class="col-12 col-md-6">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ $t('VIEWS.Report.npsHistory.title') }}</div>
						<div class="font-size-sm text-muted mt-2"></div>
					</div>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-2 pt-0">
				<div class="hello mb-3" ref="chartdiv"></div>
			</div>
		</div>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// import am4themes_animated from "@amcharts/amcharts4/themes/animated"
// am4core.useTheme(am4themes_animated);

export default {
	name: 'CustomerSatisfactionHistory',
	props: {
		data: Object,
	},
	data() {
		return {
			chart: null,
		};
	},
	mounted() {
		am4core.addLicense('CH246099470');
		am4core.options.queue = true;
		// am4core.options.onlyShowOnViewport = true;
		let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
		let maxDate = null;
		let that = this;
		chart.padding(0, 0, 0, 0);
		chart.margin(5, 5, 5, 5);

		if (this.$props.data.all_data != null) {
			this.$props.data.all_data.forEach(vc => {
				vc.date = new Date(vc.date);
				maxDate = vc.date;
			});
		}

		chart.colors.list = [
			am4core.color('#005AA9'),
			am4core.color('#0083CC'),
			am4core.color('#009D81'),
			am4core.color('#99C000'),
			am4core.color('#C9D400'),
			am4core.color('#FDCA00'),
			am4core.color('#F5A300'),
			am4core.color('#EC6500'),
			am4core.color('#E6001A'),
			am4core.color('#A600B4'),
			am4core.color('#721085'),
		];

		if (this.data.all_companies.length < 5) {
			chart.colors.step = 2;
		}

		chart.data = this.$props.data.all_data;

		let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		// dateAxis.tooltip.disabled = true;
		// dateAxis.renderer.labels.template.disabled = false;
		dateAxis.renderer.axis.visible = false;
		dateAxis.renderer.grid.template.location = 0;
		dateAxis.renderer.grid.template.disabled = true;
		dateAxis.startLocation = 0.45;
		dateAxis.endLocation = 0.55;
		dateAxis.padding(0, 0, 0, 0);
		dateAxis.showOnInit = false;

		chart.events.on("ready", function () {
			dateAxis.zoomToDates(
				(that.data.all_data[0].date < new Date().addDays(-31)) ? new Date().addDays(-31) : that.data.all_data[0].date,
				maxDate,
				false,
				true // this makes zoom instant
			);
		});

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.tooltip.disabled = true;
		valueAxis.renderer.grid.template.disabled = true;
		// valueAxis.renderer.labels.template.disabled = true;
		valueAxis.padding(0, 0, 0, 0);
		valueAxis.baseValue = -1000;
		valueAxis.startLocation = 0.1;
		valueAxis.endLocation = 2;
		valueAxis.min = -1;
		valueAxis.max = 11;
		valueAxis.strictMinMax = true;

		chart.scrollbarX = new am4charts.XYChartScrollbar();

		this.data.all_companies.forEach(value => {
			let series = chart.series.push(new am4charts.LineSeries());
			series.dataFields.dateX = 'date';
			series.dataFields.valueY = 'nps_' + value;
			series.tensionX = 0.99;
			series.baseAxis = dateAxis;
			series.legendSettings.visible = false;
			series.fillOpacity = 0;
			series.strokeWidth = 2;
			series.tooltipText = value + ': {valueY.value}';
			series.tooltip.pointerOrientation = 'down';

			chart.scrollbarX.series.push(series);
			chart.scrollbarX.scrollbarChart.series.getIndex(chart.scrollbarX.series.length - 1).xAxis.startLocation = 0.5;
			chart.scrollbarX.scrollbarChart.series.getIndex(chart.scrollbarX.series.length - 1).xAxis.endLocation = 0.5;
		});

		chart.cursor = new am4charts.XYCursor();
		chart.seriesContainer.align = 'left';
		chart.seriesContainer.contentAlign = 'left';
		// chart.seriesContainer.paddingLeft = 0;
		// chart.seriesContainer.marginLeft = 0;
		chart.dateFormatter.dateFormat = 'd.MM.yy';

		this.chart = chart;
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
};
</script>

<style scoped>
.hello {
	width: 100%;
	height: 300px;
}
.card-body {
	min-height: 20rem;
}
</style>
