<template>
	<div class="col-xl-8">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ $t('VIEWS.Report.BehaviorAnalysis.title') }}</div>
						<div class="font-size-sm text-dark-75 mt-2">{{ $t('GENERAL.General.subscriber') }}: {{ data.subscriber }}</div>
					</div>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-9 pt-2">
				<div class="row h-100 w-100">
					<div class="col-12">
						<span class="font-size-h6 text-dark-75 font-weight-normal">{{ $t('VIEWS.Report.BehaviorAnalysis.subtitle') }}: {{ data.nps }}</span>
						<div class="font-size-sm text-dark-75 font-weight-bold mt-1"></div>
					</div>
					<div class="col-xl-6 container mt-2">
						<div ref="chartdivsatisfaction" class="prerender h-100"></div>
						<!--                        <div class="row d-flex align-items-center justify-content-between h-25">-->
						<!--                            <div class="flex-grow-1">-->
						<!--                                <div class="d-flex align-items-center justify-content-between mb-10">-->
						<!--                                    <div class="d-flex align-items-center mr-2">-->
						<!--                                        <div>-->
						<!--                                            <span class="font-size-h6 text-dark-75 font-weight-normal">Die durschnittliche Zufriedenheit liegt bei: {{ data.nps }}</span>-->
						<!--                                            <div class="font-size-sm text-dark-75 font-weight-bold mt-1">-->
						<!--                                                Ihre Teilnehmer empfehlen Sie vermehrt weiter.-->
						<!--                                            </div>-->
						<!--                                        </div>-->
						<!--                                    </div>-->
						<!--                                </div>-->
						<!--                            </div>-->
						<!--                        </div>-->
<!--						<div class="row h-100">-->
<!--							<div ref="chartdivsatisfaction" class="prerender"></div>-->
<!--							<div class="hello" v-if="imgData !== ''" ref="chartImg" :style="{ 'background-image': 'url(\'' + imgData + '\')', 'background-repeat': 'no-repeat', 'background-size': 'contain', 'background-position': 'top' }"></div>-->
<!--							<div class="hello" v-if="imgData === ''">-->
<!--								<ContentLoading size="1"></ContentLoading>-->
<!--							</div>-->
<!--						</div>-->
					</div>
					<div class="col-xl-6 mt-2 pt-2">
						<div class="flex-grow-1">
							<div class="d-flex align-items-center justify-content-between mb-5 p-2 descriptionCard" style="background-color: #99C000;">
								<div class="d-flex align-items-center mr-2">
									<div>
										<span class="font-size-h6 text-dark font-weight-bold">{{ $t('VIEWS.Report.BehaviorAnalysis.loyal.title') }}</span>
										<div class="font-size-sm text-dark font-weight-normal mt-1">{{ $t('VIEWS.Report.BehaviorAnalysis.loyal.subtitle') }}</div>
									</div>
								</div>
								<div class="label label-light label-inline font-weight-bold text-dark py-4 px-3 font-size-base" style="white-space: nowrap;">{{ Number(((data.green / data.subscriber) * 100).toFixed(2)) }}% ({{ data.green }})</div>
							</div>
						</div>
						<div class="flex-grow-1">
							<div class="d-flex align-items-center justify-content-between mb-5 p-2 descriptionCard" style="background-color: #FDCA00CC;">
								<div class="d-flex align-items-center mr-2">
									<div>
										<span class="font-size-h6 text-dark font-weight-bold">{{ $t('VIEWS.Report.BehaviorAnalysis.inactive.title') }}</span>
										<div class="font-size-sm text-dark font-weight-normal mt-1">{{ $t('VIEWS.Report.BehaviorAnalysis.inactive.subtitle') }}</div>
									</div>
								</div>
								<div class="label label-light label-inline font-weight-bold text-dark py-4 px-3 font-size-base" style="white-space: nowrap;">{{ Number(((data.yellow / data.subscriber) * 100).toFixed(2)) }}% ({{ data.yellow }})</div>
							</div>
						</div>
						<div class="flex-grow-1">
							<div class="d-flex align-items-center justify-content-between mb-5 p-2 descriptionCard" style="background-color: #E6001ACC;">
								<div class="d-flex align-items-center mr-2">
									<div>
										<span class="font-size-h6 font-weight-bold" style="color: #fff !important;">{{ $t('VIEWS.Report.BehaviorAnalysis.churning.title') }}</span>
										<div class="font-size-sm font-weight-normal mt-1" style="color: #fff !important;">{{ $t('VIEWS.Report.BehaviorAnalysis.churning.subtitle') }}</div>
									</div>
								</div>
								<div class="label label-light label-inline font-weight-bold text-dark py-4 px-3 font-size-base" style="white-space: nowrap;">{{ Number(((data.red / data.subscriber) * 100).toFixed(2)) }}% ({{ data.red }})</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// import ContentLoading from '@/view/component/misc/ContentLoading';

// am4core.useTheme(am4themes_animated);

export default {
	name: 'CustomerSatisfaction',
	// components: { ContentLoading },
	props: {
		data: Object,
	},
	data() {
		return {
			imgData: '',
			chart: Object,
			isLoading: true,
		};
	},
	mounted() {
		am4core.addLicense('CH246099470');
		am4core.options.queue = true;
		// am4core.options.onlyShowOnViewport = true;


		this.buildChart();
	},
	methods: {
		buildChart: function() {
			// let that = this;
			let chart = am4core.create(this.$refs.chartdivsatisfaction, am4charts.GaugeChart);
			chart.responsive.enabled = true;
			// chart.events.on('ready', function() {
			// 	that.isLoading = false;
			// 	that.chart.exporting.getFormatOptions('png').quality = 1;
			// 	that.chart.exporting.getFormatOptions('png').scale = 4;
			// 	that.chart.exporting.getImage('png').then(function(imgData) {
			// 		that.imgData = imgData;
			// 		that.chart.dispose();
			// 	});
			// });

			chart.innerRadius = -10;

			chart.padding(0, 0, 0, 0);
			chart.margin(0, 0, 0, 0);

			let axis = chart.xAxes.push(new am4charts.ValueAxis());
			axis.min = 0;
			axis.max = 10;
			axis.strictMinMax = true;
			axis.renderer.labels.template.location = 0.5;
			axis.renderer.grid.template.location = 0.5;
			axis.startLocation = 0.5;
			axis.endLocation = 0.5;

			var range0 = axis.axisRanges.create();
			range0.value = 0;
			range0.endValue = 6.5;
			range0.axisFill.fillOpacity = 1;
			range0.axisFill.fill = am4core.color('#E6001ACC');

			var range1 = axis.axisRanges.create();
			range1.value = 6.5;
			range1.endValue = 8.5;
			range1.axisFill.fillOpacity = 1;
			range1.axisFill.fill = am4core.color('#FDCA00CC');

			var range2 = axis.axisRanges.create();
			range2.value = 8.5;
			range2.endValue = 10;
			range2.axisFill.fillOpacity = 1;
			range2.axisFill.fill = am4core.color('#99C000');

			if (this.$props.data.nps != this.$props.data.nps_all) {
				let handAll = chart.hands.push(new am4charts.ClockHand());
				handAll.radius = am4core.percent(95);
				handAll.axis = axis;
				handAll.startWidth = 10;
				handAll.pin.width = 2;
				handAll.pin.radius = 9;
				handAll.value = this.$props.data.nps_all;
				handAll.fill = am4core.color('#8c8c8caa');
				handAll.stroke = am4core.color('#8c8c8caa');

				let legend = new am4charts.Legend();
				legend.markers.template.height = 20;
				legend.markers.template.width = 20;
				legend.isMeasured = false;
				legend.y = am4core.percent(100);
				legend.verticalCenter = "bottom";
				legend.parent = chart.chartContainer;
				legend.fontSize = '0.75rem';

				legend.data = [{
					"name": "Filter",
					"fill": am4core.color('#000000')
				}, {
					"name": "Gesamt",
					"fill": am4core.color('#8c8c8caa')
				}];
			}

			let hand = chart.hands.push(new am4charts.ClockHand());
			hand.radius = am4core.percent(95);
			hand.axis = axis;
			hand.startWidth = 10;
			hand.pin.width = 2;
			hand.pin.radius = 9;
			hand.value = this.$props.data.nps;

			// let target = chart.chartAndLegendContainer;
			// let imgData = chart.exporting.normalizeSVG(
			// 	chart.exporting.serializeElement(target.dom),
			// 	{},
			// 	target.pixelWidth,
			// 	target.pixelHeight
			// );
			// this.$refs.chartImg.innerHTML += imgData;

			this.chart = chart;
		},
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	// watch: {
	// 	data: function(newData) {
	// 		this.$props.data = newData;
	// 		this.buildChart();
	// 	},
	// },
};
</script>

<style scoped>
.hello {
	width: 100%;
	height: 100%;
}

/*.prerender {*/
/*	position: fixed;*/
/*	top: -10000px;*/
/*	width: 300px;*/
/*	height: 200px;*/
/*}*/

.descriptionCard {
	border-radius: 5px;
}
</style>
