<template>
	<div>
		<ReportFilter :report="report"></ReportFilter>
		<ContentLoading v-if="isLoading" size="1.5"></ContentLoading>
		<div v-if="!isLoading" class="row">
			<DailyStatistic v-if="!!report.daily" :data="report.daily"></DailyStatistic>
			<CustomerSatisfaction v-if="!!report.nps && report.nps.nps != null" :data="report.nps"></CustomerSatisfaction>
			<CustomerSatisfactionBenchmark v-if="!!report.nps && report.nps.nps != null && report.nps.branch_score != null" :data="report.nps"></CustomerSatisfactionBenchmark>
			<CustomerSatisfactionHistory v-if="!!report.daily && !!report.nps && report.nps.nps != null" :data="report.daily"></CustomerSatisfactionHistory>

			<template v-if="report.regression.length > 0">
				<div class="col-12 mt-5 mb-2 font-weight-bold font-size-h3 text-dark-75">Einflussfaktoren</div>
				<template v-for="r in report.regression">
					<RegressionReport :title="r.name" :value="r.percent" :effect="r.effect" :key="'r' + r.name" v-if="r.effect >= 0.1"></RegressionReport>
				</template>
			</template>

			<template v-if="report.customers !== null">
				<div class="col-12 mt-5 mb-2">
					<span class="font-weight-bold font-size-h3 text-dark-75">{{ $t('VIEWS.Report.Contact.customerInfo') }}</span>
				</div>
				<ReportContact :question="report.customers" ref="customer-contact"></ReportContact>
			</template>

			<template v-for="topic in report.topics">
				<div class="col-12 mt-5 mb-2" :id="topic.id" :key="topic.id" v-if="showTopic(topic.questions)">
					<span class="font-weight-bold font-size-h3 text-dark-75">{{ topic.name }}</span>
				</div>

				<template v-for="question in topic.questions">
					<ReportOption :question="question" v-if="question.type === 'radio'" :key="'q' + question.id"></ReportOption>
					<ReportSmileys :question="question" v-if="question.type === 'smileys'" :key="'q' + question.id"></ReportSmileys>
					<ReportMultiple :question="question" v-if="question.type === 'checkbox'" :key="'q' + question.id"></ReportMultiple>
					<ReportText :npsactive="(!!report.nps && report.nps.nps != null)" :question="question" v-if="question.type === 'text'" :key="'q' + question.id"></ReportText>
					<ReportMatrix :question="question" v-if="question.type === 'matrix'" :key="'q' + question.id"></ReportMatrix>
					<ReportContact :npsactive="(!!report.nps && report.nps.nps != null)" :topics="report.topics" :pointsactive="report.point_system_active" :question="question" v-if="question.type === 'contact'" :key="'q' + question.id"></ReportContact>
				</template>
			</template>
		</div>
		<b-modal id="export-report" :title="$t('GENERAL.Buttons.export')" hide-footer>
			<b-input-group>
				<template #append>
					<b-input-group-text v-on:click.prevent="onExportReport" class="cursor-pointer">
						<i class="fas fa-download text-primary"></i>
					</b-input-group-text>
				</template>
				<b-select v-model="selectedExportOption">
					<b-select-option :value="0">{{ $t('VIEWS.Report.Export.csvWithNames') }}</b-select-option>
					<b-select-option :value="1">{{ $t('VIEWS.Report.Export.csvWithoutNames') }}</b-select-option>
					<b-select-option :value="2">{{ $t('VIEWS.Report.Export.excel') }}</b-select-option>
					<b-select-option :value="3">{{ $t('VIEWS.Report.Export.excelNumerical') }}</b-select-option>
				</b-select>
			</b-input-group>
		</b-modal>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ContentLoading from '@/view/component/misc/ContentLoading';
import CustomerSatisfaction from '@/view/component/report/CustomerSatisfaction';
import DailyStatistic from '@/view/component/report/DailyStatistic';
import ReportOption from '@/view/component/report/ReportOption';
import ReportText from '@/view/component/report/ReportText';
import ReportMatrix from '@/view/component/report/ReportMatrix';
import ReportContact from '@/view/component/report/ReportContact';
import ReportMultiple from '@/view/component/report/ReportMultiple';
import ReportFilter from '@/view/component/report/ReportFilter';
import CustomerSatisfactionBenchmark from '@/view/component/report/CustomerSatisfactionBenchmark';
import RegressionReport from '@/view/component/report/RegressionReport';
import { SET_ACTION } from '@/core/services/store/actions.module';
import { PRINOR_ACTIONS } from '@/core/services/actions.service';
import ReportSmileys from "@/view/component/report/ReportSmileys";
import CustomerSatisfactionHistory from "@/view/component/report/CustomerSatisfactionHistory";

export default {
	name: 'Report',
	components: {
		CustomerSatisfactionHistory,
		ReportSmileys,
		RegressionReport,
		CustomerSatisfactionBenchmark,
		ReportFilter,
		ReportMultiple,
		ReportContact,
		ReportMatrix,
		ReportText,
		ReportOption,
		DailyStatistic,
		CustomerSatisfaction,
		ContentLoading,
	},
	data() {
		return {
			isLoading: true,
			report: null,
			exportButtonId: null,
			selectedExportOption: 0,
			filter: {
				filter: [],
			},
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('MENU.Report.report') }]);
		this.$store.dispatch(SET_ACTION, []);
		this.exportButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Buttons.export'), this.showExportModal, 'success', 'fas fa-download', false);

		this.$on('trigger-filter', this.setData);

		this.setData([]);
	},
	methods: {
		showExportModal: function() {
			this.$bvModal.show('export-report');
		},
		onExportReport: function() {
			if (this.selectedExportOption === 0 || this.selectedExportOption === 1) {
				this.exportReport().then(data => {
					let file = window.Papa.unparse(
						{ fields: data.data.headers, data: data.data.records },
						{
							quotes: true,
							quoteChar: '"',
							escapeChar: '"',
							delimiter: ';',
							header: true,
							newline: '\r\n',
							skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
							columns: null, //or array of strings
						}
					);

					let csvContent = 'data:text/csv;charset=utf-8,' + file;
					var encodedUri = encodeURI(csvContent);

					var link = document.createElement('a');
					link.setAttribute('href', encodedUri);
					link.setAttribute('download', this.report.name + '_export_' + new Date().yyyymmdd() + '.csv');
					document.body.appendChild(link);

					link.click();
				});
			}
			else if (this.selectedExportOption === 2 || this.selectedExportOption === 3) {
				let mode = 0;
				if (this.selectedExportOption === 3)
					mode = 1;

				ApiService.setHeader();
				ApiService.post('report/export/excel/' + this.$route.params.id, { mode: mode }).then(({ data }) => {
					var encodedUri = encodeURI('data:application/xlsx;base64,' + data.data);

					var link = document.createElement('a');
					link.setAttribute('href', encodedUri);
					link.setAttribute('download', this.report.name + '_export_' + new Date().yyyymmdd() + '.xlsx');
					document.body.appendChild(link);

					link.click();
				});
			}
		},
		exportReport: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('report/export/csv/' + this.$route.params.id, { mode: this.selectedExportOption }).then(({ data }) => {
					resolve(data);
				});
			});
		},
		setData: function(filter) {
			this.filter.filter = filter;
			this.isLoading = true;
			this.get().then(data => {
				if (data.success === true) {
					this.report = data.data;
				}
				this.isLoading = false;
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('report/get/' + this.$route.params.id, this.filter).then(({ data }) => {
					resolve(data);
				});
				// .catch(({ response }) => {
				// 	console.error('Report', response);
				// });
			});
		},
		showTopic(questions) {
			if (questions) {
				if (questions.length > 0) {
					let filt = questions.filter(function(value) {
						return value.type !== 'nps';
					});
					if (filt.length > 0) {
						return true;
					}
				}
			}

			return false;
		},
	},
};
</script>

<style scoped></style>
