<template>
	<div class="col-xl-6">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ question.name }}</div>
						<div class="font-size-sm text-muted mt-2">{{ $t('GENERAL.General.subscriber') }}: {{ question.amount }}</div>
					</div>
				</div>
				<div class="card-toolbar" v-if="hasOthers">
					<a href="#" class="btn btn-sm btn-icon" v-on:click.prevent="showOthersModal">
						<i class="fas fa-table"></i>
					</a>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-0 align-items-center" v-if="!chartFilled">
				<span class="mx-auto my-auto position-absolute empty-chart">
					<i class="far fa-chart-bar"></i>
				</span>
				<span class="mx-auto my-auto position-absolute empty-chart-text">{{ $t('VIEWS.Report.noData') }}</span>
			</div>
			<div class="card-body d-flex flex-column px-0" v-if="chartFilled">
				<ContentLoading size="1.5" v-if="isLoading"></ContentLoading>
				<div class="hello mb-2" ref="chartdiv"></div>
				<div class="flex-grow-1 card-spacer-x" v-if="chart != null">
					<div
						ref="cont"
						class="d-flex align-items-center justify-content-between pt-5"
						v-for="(col, index) in chart.series.values[0].columns.values"
						v-on:mouseenter="onHover(index)"
						v-on:mouseleave="onLeave()"
						:key="col.dataItem.dataContext.id + index"
					>
						<div class="d-flex align-items-center mr-2">
							<div>
								<span class="font-size-h6 text-dark-75 font-weight-normal">{{ col.dataItem.dataContext.name }}</span>
								<div class="font-size-sm text-muted font-weight-bold mt-1"></div>
							</div>
						</div>
						<div class="label label-light label-inline font-weight-bold text-dark-75 py-4 px-3 font-size-h6 text-monospace">{{ col.dataItem.values.valueY.percent.toFixed(2) }}% ({{ col.dataItem.valueY }})</div>
					</div>
				</div>
			</div>
		</div>
		<b-modal :id="'others-table-' + question.id" :title="$t('VIEWS.Report.Option.others')" ok-only>
			<div class="prinor-table">
				<b-table :data="othersData" :paginated="true" :per-page="5" :default-sort-direction="'asc'" :sort-icon="'caret-up'">
					<b-table-column field="value" :label="$t('VIEWS.Report.Text.text')" v-slot="props" :sortable="true" :searchable="true" :cell-class="'d-grid'">
						<template>
							<span class="truncate">{{ props.row.value }}</span>
						</template>
					</b-table-column>
				</b-table>
			</div>
		</b-modal>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ContentLoading from "@/view/component/misc/ContentLoading";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated"
//
// am4core.useTheme(am4themes_animated);

export default {
	name: 'ReportMultiple',
	components: {ContentLoading},
	props: {
		question: Object,
	},
	data() {
		return {
			chart: null,
			colors: [],
			chartFilled: true,
			options: [],
			subscriber: 0,
			hasOthers: false,
			othersData: [],
			isLoading: true,
		};
	},
	mounted: function() {
		am4core.addLicense('CH246099470');
		am4core.options.queue = true;
		// am4core.options.onlyShowOnViewport = true;

		if (this.$props.question.amount > 0) {
			this.buildChart();
		} else {
			this.isLoading = false;
			this.chartFilled = false;
		}

		this.question.answers.forEach(answer => {
			if (answer.texts.length > 0) {
				this.hasOthers = true;
				this.othersData = answer.texts;
				answer.name = this.$t('VIEWS.Report.Option.others');
			}
		});
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods: {
		showOthersModal: function() {
			this.$bvModal.show('others-table-' + this.question.id);
		},
		onHover: function(index) {
			let that = this;
			this.chart.series.values[0].columns.values.forEach(function(column, i) {
				column.fill = that.colors[i];
				if (i !== index) {
					column.fill = am4core.color('#a3a3a3');
				}
			});
		},
		onLeave: function() {
			let that = this;
			this.chart.series.values[0].columns.values.forEach(function(column, i) {
				column.fill = that.colors[i];
			});
		},
		buildChart: function() {
			let that = this;
			let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

			chart.events.on('ready', function() {
				that.isLoading = false;
			});

			chart.padding(0, 0, 0, 0);
			chart.margin(0, 0, 0, 0);

			chart.data = this.$props.question.answers;


			chart.colors.list = [
				am4core.color('#005AA9'),
				am4core.color('#0083CC'),
				am4core.color('#009D81'),
				am4core.color('#99C000'),
				am4core.color('#C9D400'),
				am4core.color('#FDCA00'),
				am4core.color('#F5A300'),
				am4core.color('#EC6500'),
				am4core.color('#E6001A'),
				am4core.color('#A600B4'),
				am4core.color('#721085'),
			];

			if (chart.data.length < 5) {
				chart.colors.step = 2;
			}

			let answerAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			answerAxis.tooltip.disabled = true;
			answerAxis.dataFields.category = 'id';
			answerAxis.renderer.labels.template.disabled = true;
			answerAxis.renderer.axis.visible = false;
			answerAxis.renderer.grid.template.location = 0;
			answerAxis.renderer.grid.template.disabled = true;
			answerAxis.padding(0, 0, 0, 0);

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.tooltip.disabled = true;
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.labels.template.disabled = false;
			valueAxis.baseValue = -1000;
			valueAxis.padding(5, 0, 5, 0);
			valueAxis.min = 0;
			valueAxis.max =
				Math.max.apply(
					Math,
					this.$props.question.answers.map(function(o) {
						return o.amount;
					})
				) + 1;

			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.categoryX = 'id';
			series.dataFields.valueY = 'amount';
			series.calculatePercent = true;
			series.tooltipText = '{valueY.percent}% ({valueY.value})';
			series.tooltip.pointerOrientation = 'down';
			series.columns.template.stroke = am4core.color('#fff');
			series.columns.template.strokeOpacity = 1;

			series.columns.template.adapter.add('fill', function(fill, target) {
				if (chart.colors.step === 2) {
					return chart.colors.getIndex(target.dataItem.index * 2);
				} else {
					return chart.colors.getIndex(target.dataItem.index);
				}
			});

			series.events.on('ready', function() {
				that.colors = [];
				series.columns.each(function(column) {
					that.colors.push(column.fill);
				});
			});

			chart.cursor = new am4charts.XYCursor();
			chart.seriesContainer.align = 'left';
			chart.seriesContainer.contentAlign = 'left';
			chart.seriesContainer.paddingLeft = 0;
			chart.seriesContainer.marginLeft = 0;
			chart.numberFormatter.numberFormat = '#,###.##';

			this.chart = chart;
		},
	},
	watch: {
		question: function(newQuestion) {
			this.$props.question = newQuestion;
			if (this.chart != null) {
				this.chart.data = this.$props.question.answers;
			}
		},
	},
};
</script>

<style scoped>
.hello {
	width: 100%;
	height: 250px;
}

.empty-chart {
	top: calc(50% - 7.5rem);
}

.empty-chart > i {
	font-size: 15rem;
	color: #e0e0e0;
}

.empty-chart-text {
	top: calc(50% - 1.5rem);
	font-size: 3rem;
	text-align: center;
}

.card-body {
	min-height: 20rem;
}
</style>
