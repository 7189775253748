<template>
	<div class="col-12">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ question.name }}</div>
						<div class="font-size-sm text-muted mt-2">{{ $t('GENERAL.General.subscriber') }}: {{ question.amount }}</div>
					</div>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-0 align-items-center" v-if="!chartFilled">
				<span class="mx-auto my-auto position-absolute empty-chart">
					<i class="far fa-chart-bar"></i>
				</span>
				<span class="mx-auto my-auto position-absolute empty-chart-text">{{ $t('VIEWS.Report.noData') }}</span>
			</div>
			<div class="card-body d-flex flex-column px-0" v-if="chartFilled">
				<ContentLoading size="1.5" v-if="isLoading"></ContentLoading>
				<div class="hello mb-5" ref="chartdiv" :style="{ height: question.matrix_questions.length * 75 + 'px' }"></div>
				<div class="flex-grow-1 card-spacer-x" v-if="chart != null && question.matrix_questions.length !== 0 && !!chart.series.values[0]">
					<div v-for="(col, index) in chart.series.values[0].columns.values" v-on:click="toggleDetails(index)" :key="col.dataItem.dataContext.id + index">
						<div ref="cont" class="d-flex align-items-center justify-content-between py-2 matrixContainer" v-on:mouseenter="onHover(index)" v-on:mouseleave="onLeave()">
							<div class="d-flex align-items-center mr-2">
								<div class="align-center align-items-center">
									<i :class="visibleDetails[index] === true ? 'fa-caret-down' : 'fa-caret-right'" class="fas mr-3"></i>
									<span class="font-size-h6 text-dark-75 font-weight-normal">{{ col.dataItem.dataContext.name }}</span>
									<div class="font-size-sm text-muted font-weight-bold mt-1"></div>
								</div>
							</div>
							<div class="label label-light label-inline font-weight-bold text-dark-75 py-4 px-3 font-size-base text-monospace">
								{{ col.dataItem.dataContext.amount }}
							</div>
						</div>
						<transition name="slide">
							<div v-if="visibleDetails[index] === true">
								<div class="row" v-for="(ans, ansIndex) in col.dataItem.dataContext.answers" :key="ans.id">
									<div class="col-1" v-if="ansIndex === 0"></div>
									<div class="col-11" v-if="ansIndex === 0">
										<hr />
									</div>
									<div class="col-1"></div>
									<div class="col-7 font-weight-normal text-dark-75 py-0 px-3 font-size-h6">
										{{ ans.name }}
									</div>
									<div class="col-2 font-weight-normal text-dark-75 py-0 px-3 font-size-h6 text-monospace">{{ ans.percent }}%</div>
									<div class="col-2 font-weight-normal text-dark-75 py-0 px-3 font-size-h6 text-monospace">({{ ans.amount }})</div>
									<div class="col-1"></div>
									<div class="col-11">
										<hr />
									</div>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ContentLoading from "@/view/component/misc/ContentLoading";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated"
//
// am4core.useTheme(am4themes_animated);

export default {
	name: 'ReportMatrix',
	components: {ContentLoading},
	props: {
		question: Object,
	},
	data() {
		return {
			chart: null,
			colorPositive: [],
			colorNegative: [],
			visibleDetails: [],
			chartFilled: true,
			subscriber: 0,
			matrix: [],
			isLoading: true,
		};
	},
	mounted: function() {
		am4core.addLicense('CH246099470');
		am4core.options.queue = true;
		// am4core.options.onlyShowOnViewport = true;

		if (this.$props.question.amount > 0) {
			this.calcAnswers();
		} else {
			this.isLoading = false;
			this.chartFilled = false;
		}
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods: {
		onHover: function(index) {
			index;
			// let that = this;
			// this.chart.series.values[0].columns.values.forEach(function (column, i) {
			//     if (i !== index) {
			//         column.fill = am4core.color('#a3a3a3');
			//     } else {
			//         column.fill = that.colorPositive[i];
			//     }
			// });
			// this.chart.series.values[1].columns.values.forEach(function (column, i) {
			//     if (i !== index) {
			//         column.fill = am4core.color('#a3a3a3');
			//     } else {
			//         column.fill = that.colorNegative[i];
			//     }
			// });
		},
		onLeave: function() {
			// let that = this;
			// this.chart.series.values[0].columns.values.forEach(function (column, i) {
			//     column.fill = that.colorPositive[i];
			// });
			// this.chart.series.values[1].columns.values.forEach(function (column, i) {
			//     column.fill = that.colorNegative[i];
			// });
		},
		createSeries: function(chart) {
			if (!!chart && !!chart.data && chart.data.length > 0) {
				Object.keys(chart.data[0]).forEach(key => {
					if (key.indexOf('option') > -1 && key.indexOf('_percent') > -1) {
						let names = key.split('_');

						let series = chart.series.push(new am4charts.ColumnSeries());
						series.dataFields.categoryY = 'name';
						series.dataFields.valueX = key;
						series.stacked = true;
						series.name = chart.data[0][names[0] + '_name'];
						series.columns.template.tooltipText = '{name}: {valueX.value} %';
						series.tooltip.disabled = true;
						series.columns.template.events.on('ready', this.hideZero);
						series.columns.template.events.on('visibilitychanged', this.hideZero);

						let labelBullet = series.bullets.push(new am4charts.LabelBullet());
						labelBullet.label.horizontalCenter = 'left';
						labelBullet.label.dx = 10;
						labelBullet.label.text = '{valueX} %';
						labelBullet.locationX = 0.5;
						labelBullet.interactionsEnabled = false;
						labelBullet.label.adapter.add('textOutput', function(text, target) {
							if (target.dataItem && target.dataItem.valueX === 0) {
								return '';
							}
							return text;
						});
					}
				});
			}
		},
		toggleDetails: function(index) {
			this.$set(this.visibleDetails, index, !this.visibleDetails[index]);
		},
		hideZero: function(ev) {
			if (ev.target.dataItem && ev.target.dataItem.valueY === 0) {
				ev.target.hide();
			} else {
				ev.target.show();
			}
		},
		buildChart() {
			let that = this;
			let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

			chart.events.on('ready', function() {
				that.isLoading = false;
			});

			chart.padding(10, 0, 10, 0);
			chart.margin(0, 0, 0, 0);

			chart.data = this.matrix;

			chart.colors.list = [
				am4core.color('#005AA9'),
				am4core.color('#0083CC'),
				am4core.color('#009D81'),
				am4core.color('#99C000'),
				am4core.color('#C9D400'),
				am4core.color('#FDCA00'),
				am4core.color('#F5A300'),
				am4core.color('#EC6500'),
				am4core.color('#E6001A'),
				am4core.color('#A600B4'),
				am4core.color('#721085'),
			];

			if (chart.data.length < 5) {
				chart.colors.step = 2;
			}

			chart.legend = new am4charts.Legend();
			chart.legend.position = 'bottom';
			chart.legend.tooltip.disabled = true;

			let answerAxis = chart.yAxes.push(new am4charts.CategoryAxis());
			answerAxis.tooltip.disabled = true;
			answerAxis.dataFields.category = 'name';
			answerAxis.renderer.grid.template.opacity = 0;
			answerAxis.renderer.labels.template.wrap = true;
			answerAxis.renderer.labels.template.maxWidth = 400;
			answerAxis.renderer.labels.template.align = 'right';
			answerAxis.renderer.labels.template.textAlign = 'end';
			// answerAxis.renderer.labels.template.disabled = true;
			// answerAxis.renderer.axis.visible = false;
			answerAxis.renderer.grid.template.location = 0;
			answerAxis.renderer.grid.template.disabled = true;
			answerAxis.padding(0, 0, 0, 10);

			let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
			valueAxis.tooltip.disabled = true;
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.labels.template.disabled = true;
			valueAxis.renderer.axis.visible = false;
			// valueAxis.baseValue = 0;
			valueAxis.padding(0, 10, 15, 0);
			valueAxis.min = 0;
			valueAxis.max = 100;
			// valueAxis.min = -100;
			valueAxis.renderer.grid.template.opacity = 0;
			valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
			valueAxis.renderer.ticks.template.stroke = am4core.color('#495C43');
			valueAxis.renderer.ticks.template.length = 10;
			valueAxis.renderer.line.strokeOpacity = 0.5;
			valueAxis.renderer.baseGrid.disabled = true;
			valueAxis.renderer.minGridDistance = 40;

			this.createSeries(chart);

			this.matrix.forEach(() => {
				this.visibleDetails.push(false);
			});

			chart.seriesContainer.align = 'left';
			chart.seriesContainer.contentAlign = 'left';
			chart.seriesContainer.paddingLeft = 0;
			chart.seriesContainer.marginLeft = 0;

			this.chart = chart;
		},
		calcAnswers: function() {
			let matrix = this.$props.question.matrix_questions;
			if (matrix.length > 0) {
				matrix.forEach(m => {
					let index = 0;
					m.answers.forEach(a => {
						m['option' + index + '_name'] = a.name;
						m['option' + index + '_amount'] = a.amount;
						m['option' + index + '_percent'] = Math.round(((a.amount / m.amount) * 100 + Number.EPSILON) * 100) / 100;
						if (isNaN(m['option' + index + '_percent'])) {
							m['option' + index + '_percent'] = 0;
						}
						a.percent = m['option' + index + '_percent'];
						index++;
					});
				});
			}
			this.matrix = matrix;

			if (this.chart === null) {
				this.buildChart();
			}

			this.chart.data = this.matrix;
		},
	},
	watch: {
		question: function(newQuestion) {
			this.$props.question = newQuestion;
			if (this.chart != null) {
				this.calcAnswers();
			}
		},
	},
};
</script>

<style scoped>
.empty-chart {
	top: calc(50% - 5.5rem);
}

.empty-chart > i {
	font-size: 15rem;
	color: #e0e0e0;
}

.empty-chart-text {
	top: calc(50% - 0.5rem);
	font-size: 3rem;
	text-align: center;
}

.card-body {
	min-height: 20rem;
}

.hello {
	width: 100%;
	/*min-height: 250px;*/
}

.matrixContainer {
	cursor: pointer;
	line-height: 1rem;
}

.matrixContainer:hover {
	background-color: lightgrey;
}

.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
