<template>
	<div class="col-12 col-md-6">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ $t('VIEWS.Report.NpsBenchmark.title') }}</div>
						<div class="font-size-sm text-dark-75 mt-2">{{ $t('VIEWS.Administration.Company.General.industry') }}: {{ data.branch_name }}</div>
					</div>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-0 container pt-2">
				<div ref="chartdiv" class="hello"></div>
			</div>
		</div>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_timeline from '@amcharts/amcharts4/plugins/timeline';

export default {
	name: 'CustomerSatisfactionBenchmark',
	props: {
		data: Object,
	},
	data() {
		return {
			chart: Object,
		};
	},
	computed: {
		greenPercent: function() {
			return Number(((this.$props.data.green / this.$props.data.subscriber) * 100).toFixed(2));
		},
		redPercent: function() {
			return Number(((this.$props.data.red / this.$props.data.subscriber) * 100).toFixed(2));
		},
	},
	mounted() {
		am4core.addLicense('CH246099470');
		am4core.addLicense('TL245513734');
		am4core.options.queue = true;
		// am4core.options.onlyShowOnViewport = true;
		this.buildChart();

		// let outerCircle = bullet.createChild(am4core.Circle);
		// outerCircle.radius = 30;
		// outerCircle.fillOpacity = 0;
		// outerCircle.strokeOpacity = 0;
		// outerCircle.strokeDasharray = "1,3";
		//
		// let hoverState = outerCircle.states.create("hover");
		// hoverState.properties.strokeOpacity = 0.8;
		// hoverState.properties.scale = 1.5;

		// outerCircle.events.on("over", function(event){
		//     let circle = event.target.parent.children.getIndex(1);
		//     circle.isHover = true;
		//     event.target.stroke = circle.fill;
		//     event.target.radius = circle.pixelRadius;
		//     event.target.animate({property: "rotation", from: 0, to: 360}, 4000, am4core.ease.sinInOut);
		// });
		//
		// outerCircle.events.on("out", function(event){
		//     let circle = event.target.parent.children.getIndex(1);
		//     circle.isHover = false;
		// });
	},
	methods: {
		getColor: function(val) {
			if (val <= 15) {
				return '#E6001A';
			} else if (val > 15 && val <= 30) {
				return '#F5A300';
			} else if (val > 30 && val <= 45) {
				return '#FDCA00';
			} else if (val > 45 && val <= 60) {
				return '#C9D400';
			} else {
				return '#99C000';
			}
		},
		buildChart: function() {
			let score = this.greenPercent - this.redPercent;
			score = Math.round(score);

			let chart = am4core.create(this.$refs.chartdiv, am4plugins_timeline.CurveChart);
			chart.curveContainer.padding(0, 100, 0, 120);
			chart.maskBullets = false;

			chart.data = [
				{
					category: '',
					value: '-100',
					size: 3,
					text: '',
					color: '#E6001A',
				},
				{
					category: '',
					value: '' + score,
					size: 5,
					text: this.$t('VIEWS.Report.NpsBenchmark.score') + ': ' + score,
					color: this.getColor(score),
				},
				{
					category: '',
					value: '' + this.$props.data.branch_score,
					size: 4,
					text: this.$t('VIEWS.Report.NpsBenchmark.average') + ': ' + this.$props.data.branch_score,
					color: this.getColor(this.$props.data.branch_score),
				},
				{
					category: '',
					value: '+100',
					size: 3,
					text: '',
					color: '#99C000',
				},
			];

			chart.dateFormatter.inputDateFormat = 'yyyy';

			chart.fontSize = 11;
			chart.tooltipContainer.fontSize = 11;

			let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = 'category';
			categoryAxis.renderer.grid.template.disabled = true;
			categoryAxis.renderer.strokeOpacity = 0;

			let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.points = [
				{ x: -400, y: 0 },
				{ x: 400, y: 0 },
			];
			valueAxis.renderer.polyspline.tensionX = 0.8;
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.labels.template.disabled = true;
			valueAxis.renderer.strokeOpacity = 0;
			valueAxis.min = -100;
			valueAxis.max = 100;

			let series = chart.series.push(new am4plugins_timeline.CurveLineSeries());
			series.strokeOpacity = 1;
			series.strokeWidth = 10;
			series.dataFields.valueX = 'value';
			series.dataFields.categoryY = 'category';
			// series.dataFields.value = "size";
			series.baseAxis = categoryAxis;

			series.tooltip.pointerOrientation = 'down';
			series.tooltip.disabled = true;

			let distance = 100;
			let angle = 60;

			let bullet = series.bullets.push(new am4charts.Bullet());

			let line = bullet.createChild(am4core.Line);
			line.adapter.add('stroke', function(fill, target) {
				if (target.dataItem) {
					return target.dataItem.dataContext.color;
				}
			});

			line.x1 = 5;
			line.y1 = 0;
			line.y2 = 0;
			line.x2 = distance - 30;
			// line.strokeDasharray = "1,1";
			line.strokeWidth = 3;
			// line.stroke = am4core.color("#000");
			// line.realStroke = am4core.color("#000");
			// line.fill = am4core.color("#000");
			// line.realFill = am4core.color("#000");

			let circle = bullet.createChild(am4core.Circle);
			circle.radius = 20;
			circle.fillOpacity = 1;
			circle.strokeOpacity = 0;

			let circleHoverState = circle.states.create('hover');
			circleHoverState.properties.scale = 1.3;

			let index = 0;
			series.heatRules.push({ target: circle, min: 20, max: 50, property: 'radius' });
			circle.adapter.add('fill', function(fill, target) {
				if (target.dataItem) {
					if (index > 0 && index < 3) {
						target.fillOpacity = 0;
					}
					index++;
					return target.dataItem.dataContext.color;
				}
			});
			circle.tooltipText = '{text}: {value}';
			circle.adapter.add('tooltipY', function(tooltipY, target) {
				return -target.pixelRadius - 4;
			});

			let yearLabel = bullet.createChild(am4core.Label);
			yearLabel.text = '{valueX}';
			yearLabel.strokeOpacity = 0;
			yearLabel.fill = am4core.color('#fff');
			yearLabel.horizontalCenter = 'middle';
			yearLabel.verticalCenter = 'middle';
			yearLabel.interactionsEnabled = false;
			yearLabel.fontSize = 14;
			yearLabel.fontWeight = 'bold';

			let indexLabel = 0;
			yearLabel.adapter.add('hidden', function() {
				if (indexLabel > 0 && indexLabel < 3) {
					indexLabel++;
					return true;
				} else {
					indexLabel++;
					return false;
				}
			});

			let range1 = valueAxis.createSeriesRange(series);
			range1.value = -100;
			range1.endValue = 15;
			range1.contents.stroke = am4core.color('#E6001A');
			range1.contents.fill = range1.contents.stroke;
			let range2 = valueAxis.createSeriesRange(series);
			range2.value = 15;
			range2.endValue = 30;
			range2.contents.stroke = am4core.color('#F5A300');
			range2.contents.fill = range2.contents.stroke;
			let range3 = valueAxis.createSeriesRange(series);
			range3.value = 30;
			range3.endValue = 45;
			range3.contents.stroke = am4core.color('#FDCA00');
			range3.contents.fill = range3.contents.stroke;
			let range4 = valueAxis.createSeriesRange(series);
			range4.value = 45;
			range4.endValue = 60;
			range4.contents.stroke = am4core.color('#C9D400');
			range4.contents.fill = range4.contents.stroke;
			let range5 = valueAxis.createSeriesRange(series);
			range5.value = 60;
			range5.endValue = 100;
			range5.contents.stroke = am4core.color('#99C000');
			range5.contents.fill = range5.contents.stroke;

			let label = bullet.createChild(am4core.Label);
			label.propertyFields.text = 'text';
			label.strokeOpacity = 0;
			label.horizontalCenter = 'middle';
			label.verticalCenter = 'middle';
			label.fontSize = 18;

			label.adapter.add('opacity', function(opacity, target) {
				if (target.dataItem) {
					let index = target.dataItem.index;
					let line = target.parent.children.getIndex(0);

					if (index === 0 || index === 3) {
						line.opacity = 0;
					}

					if (index % 2 == 0) {
						target.y = -distance * am4core.math.sin(-angle);
						// target.x = -distance * am4core.math.cos(-angle);
						line.rotation = +90;
						// target.rotation = -angle;
					} else {
						target.y = -distance * am4core.math.sin(angle);
						// target.x = -distance * am4core.math.cos(angle);
						line.rotation = -90;
						// target.rotation = angle;
					}
				}
				return 1;
			});

			this.chart = chart;
		},
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
};
</script>

<style scoped>
.hello {
	width: 100%;
	height: 200px;
}
</style>
