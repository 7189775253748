<template>
	<div class="col-xl-6">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ question.name }}</div>
						<div class="font-size-sm text-muted mt-2">{{ $t('GENERAL.General.subscriber') }}: {{ question.amount }}</div>
					</div>
				</div>
				<div class="card-toolbar" v-if="hasOthers">
					<a href="#" class="btn btn-sm btn-icon" v-on:click.prevent="showOthersModal">
						<i class="fas fa-table"></i>
					</a>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-0 align-items-center" v-if="!chartFilled">
				<span class="mx-auto my-auto position-absolute empty-chart">
					<i class="far fa-chart-bar"></i>
				</span>
				<span class="mx-auto my-auto position-absolute empty-chart-text">{{ $t('VIEWS.Report.noData') }}</span>
			</div>
			<div class="card-body d-flex flex-column px-0" v-if="chartFilled">
				<ContentLoading size="1.5" v-if="isLoading"></ContentLoading>
				<div class="hello mb-2" ref="chartdiv"></div>
				<div class="flex-grow-1 card-spacer-x" v-if="chart != null">
					<div ref="cont" class="d-flex align-items-center justify-content-between pt-5" v-for="(col, index) in chart.series.values[0].dataItems.values" v-on:mouseenter="onHover(index)" v-on:mouseleave="onLeave()" :key="index">
						<div class="d-flex align-items-center mr-2">
							<div>
								<span class="font-size-h6 text-dark-75 font-weight-normal smileys">
									<template v-if="index === 0">
										<img :src="baseUrl + '/media/smileys/face_with_steam_from_nose.gif'" />
									</template>
									<template v-if="index === 1">
										<img :src="baseUrl + '/media/smileys/frowning_face.gif'" />
									</template>
									<template v-if="index === 2">
										<img :src="baseUrl + '/media/smileys/smiling_face.gif'" />
									</template>
									<template v-if="index === 3">
										<img :src="baseUrl + '/media/smileys/smiling_face_with_heart_eyes.gif'" />
									</template>
								</span>
								<div class="font-size-sm text-muted font-weight-bold mt-1"></div>
							</div>
						</div>
						<div class="label label-light label-inline font-weight-bold text-dark-75 py-4 px-3 font-size-h6 text-monospace">{{ col.values.value.percent.toFixed(2) }}% ({{ col.dataContext.amount }})</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ContentLoading from "@/view/component/misc/ContentLoading";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated"
//
// am4core.useTheme(am4themes_animated);

export default {
	name: 'ReportSmileys',
	components: {ContentLoading},
	props: {
		question: Object,
	},
	data() {
		return {
			chart: null,
			colors: [],
			chartFilled: true,
			hasOthers: false,
			othersData: [],
			isLoading: true,
		};
	},
	computed: {
		baseUrl() {
			return process.env.VUE_APP_URL;
		},
	},
	mounted: function() {
		am4core.addLicense('CH246099470');
		am4core.options.queue = true;
		// am4core.options.onlyShowOnViewport = true;

		if (this.$props.question.amount > 0) {
			this.buildChart();
		} else {
			this.isLoading = false;
			this.chartFilled = false;
		}

		this.question.answers.forEach(answer => {
			if (answer.texts.length > 0) {
				this.hasOthers = true;
				this.othersData = answer.texts;
				answer.name = this.$t('VIEWS.Report.Option.others');
			}
		});
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods: {
		showOthersModal: function() {
			this.$bvModal.show('others-table-' + this.question.id);
		},
		onHover: function(index) {
			this.chart.series.values[0].slices.values.forEach(function(slice, i) {
				if (i !== index) {
					slice.isActive = false;
				} else {
					slice.isActive = true;
				}
			});
		},
		onLeave: function() {
			this.chart.series.values[0].slices.values.forEach(function(slice) {
				slice.isActive = false;
			});
		},
		hideZero: function(ev) {
			if (ev.target.dataItem && ev.target.dataItem.value === 0) {
				ev.target.hide();
			} else {
				ev.target.show();
			}
		},
		buildChart: function() {
			let that = this;
			let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);

			chart.events.on('ready', function() {
				that.isLoading = false;
			});

			chart.padding(0, 0, 0, 0);
			chart.margin(0, 0, 0, 0);

			chart.data = this.$props.question.answers;

			let series = chart.series.push(new am4charts.PieSeries());
			series.dataFields.value = 'amount';
			series.dataFields.category = 'name';
			series.labels.template.disabled = false;
			series.labels.template.wrap = true;
			series.calculatePercent = true;
			series.labels.template.text = '{value.percent}%';
			series.slices.template.tooltipText = '{value.percent}% ({value.value})';
			series.slices.template.stroke = am4core.color('#fff');
			series.slices.template.strokeOpacity = 1;
			series.slices.template.strokeWidth = 2;
			series.hiddenState.properties.opacity = 1;
			series.hiddenState.properties.endAngle = -90;
			series.hiddenState.properties.startAngle = -90;

			series.ticks.template.events.on('ready', this.hideZero);
			series.ticks.template.events.on('visibilitychanged', this.hideZero);
			series.labels.template.events.on('ready', this.hideZero);
			series.labels.template.events.on('visibilitychanged', this.hideZero);

			series.colors.list = [
				am4core.color('#005AA9'),
				am4core.color('#0083CC'),
				am4core.color('#009D81'),
				am4core.color('#99C000'),
				am4core.color('#C9D400'),
				am4core.color('#FDCA00'),
				am4core.color('#F5A300'),
				am4core.color('#EC6500'),
				am4core.color('#E6001A'),
				am4core.color('#A600B4'),
				am4core.color('#721085'),
			];

			chart.numberFormatter.numberFormat = '#,###.##';
			chart.seriesContainer.align = 'center';
			chart.seriesContainer.contentAlign = 'left';
			chart.seriesContainer.paddingLeft = 0;
			chart.seriesContainer.marginLeft = 0;

			this.chart = chart;
		},
	},
	updated: function() {
		var ref = this;
		this.$nextTick(function() {
			if (!!ref.chart && !!ref.$refs.chartdiv) {
				ref.chart.series.values[0].labels.template.maxWidth = (ref.$refs.chartdiv.clientWidth - ref.chart.series.values[0].measuredWidth) / 2.25;
			}
		});
	},
	watch: {
		question: function(newQuestion) {
			this.$props.question = newQuestion;
			if (this.chart != null) {
				this.chart.data = this.$props.question.answers;
			}
		},
	},
};
</script>

<style scoped>
.hello {
	width: 100%;
	height: 250px;
}

.empty-chart {
	top: calc(50% - 7.5rem);
}

.empty-chart > i {
	font-size: 15rem;
	color: #e0e0e0;
}

.empty-chart-text {
	top: calc(50% - 1.5rem);
	font-size: 3rem;
	text-align: center;
}

.card-body {
	min-height: 20rem;
}

span.smileys img {
	width: 3rem;
}
</style>
