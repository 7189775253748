<template>
	<div class="col-12" :id="question.id">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ question.name }}</div>
						<div class="font-size-sm text-muted mt-2">{{ $t('GENERAL.General.subscriber') }}: {{ question.amount }}</div>
					</div>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-0 align-items-center" v-if="!chartFilled">
				<span class="mx-auto my-auto position-absolute empty-chart">
					<i class="fas fa-table"></i>
				</span>
				<span class="mx-auto my-auto position-absolute empty-chart-text">{{ $t('VIEWS.Report.noData') }}</span>
			</div>
			<div class="card-body d-flex flex-column px-0" v-if="chartFilled">
				<div class="px-3 prinor-table">
					<b-table ref="contactTable" :data="contactList" :paginated="true" :per-page="5" default-sort="nps" :default-sort-direction="'asc'" :sort-icon="'caret-up'" :sort-icon-size="'is-small'" @dblclick="rowClicked">
						<template v-for="col in tableColumns">
							<b-table-column :field="col.field" :label="col.label" :sortable="true" :searchable="true" v-slot="props" :key="'field-' + col.field">
								<template>{{ props.row.contact_answers[col.field] }}</template>
							</b-table-column>
						</template>

						<b-table-column field="nps" :label="$t('VIEWS.Report.Text.recommendation')" :sortable="true" :searchable="true" v-slot="props" v-if="npsactive">
							<template>
								<span v-if="typeof props.row.nps === 'undefined'"></span>
								<span v-else-if="props.row.nps < 7">
									<i class="far fa-frown text-danger"></i>
									({{ props.row.nps }})
								</span>
								<span v-else-if="props.row.nps > 6 && props.row.nps < 9">
									<i class="far fa-meh text-warning"></i>
									({{ props.row.nps }})
								</span>
								<span v-else>
									<i class="far fa-smile text-success"></i>
									({{ props.row.nps }})
								</span>
							</template>
						</b-table-column>
					</b-table>
				</div>
			</div>
		</div>
		<vue-final-modal v-model="contactVisible" classes="modal-container" content-class="modal-content" :name="'contact-details' + this._uid" :min-width="1200" :min-height="600" :max-height="'100vh'" :height="'auto'" :resize="true" :drag="true" drag-selector=".modal-drag">
			<div class="card card-custom " style="display: contents;">
				<div class="card-header card-header-tabs-line modal-drag">
					<div class="card-title w-100 d-flex justify-content-between modal-drag">
						<h3 class="card-label modal-drag">{{ $t('VIEWS.Report.Contact.contactInformation') }}</h3>
						<h3 class="card-label" v-if="npsactive">
							<div v-if="contactDetails.nps < 7">
								<i class="far fa-frown text-danger"></i>
								<span class="my-auto ml-3">{{ contactDetails.nps }}</span>
							</div>
							<div v-else-if="contactDetails.nps > 6 && contactDetails.nps < 9">
								<i class="far fa-meh text-warning"></i>
								<span class="my-auto ml-3">{{ contactDetails.nps }}</span>
							</div>
							<div v-else>
								<i class="far fa-smile text-success"></i>
								<span class="my-auto ml-3">{{ contactDetails.nps }}</span>
							</div>
						</h3>
						<a v-if="question.id === 'd9bef8b6-7bd6-441e-a7c1-f461fdded7cc'" href="" class="btn btn-icon btn-light btn-sm mx-1" v-on:click.prevent="downloadGraphs">
							<i class="fas fa-download text-primary"></i>
						</a>
					</div>
				</div>
				<div class="card-body modal__content">
					<div class="row" v-if="contactDetails.is_customer === false">
						<div class="col-12 col-md-6" v-for="(field, i) in question.contact.fields" :key="'field-' + i">
							<div class="form-group">
								<label>{{ field.name }}</label>
								<input type="text" v-model="contactDetails.contact_answers[field.id]" class="form-control" disabled />
							</div>
						</div>
					</div>
					<div class="row" v-if="contactDetails.is_customer === true">
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Create.Placeholder.firstName') }}</label>
								<input type="text" v-model="contactDetails.contact_answers['firstname']" class="form-control" disabled />
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Create.Placeholder.lastName') }}</label>
								<input type="text" v-model="contactDetails.contact_answers['lastname']" class="form-control" disabled />
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Create.Placeholder.email') }}</label>
								<input type="text" v-model="contactDetails.contact_answers['email']" class="form-control" disabled />
							</div>
						</div>
						<div class="col-12 col-md-6">
							<div class="form-group">
								<label>{{ $t('VIEWS.Survey.Create.Placeholder.phone') }}</label>
								<input type="text" v-model="contactDetails.contact_answers['phone']" class="form-control" disabled />
							</div>
						</div>
					</div>
					<div v-if="pointsactive && pointsactive === true" class="row mb-3">
						<div class="col-6">
							<h4>Gesamtpunkte:</h4>
						</div>
						<div class="col-6">
							<h5>Positiv: {{ getTotalPoints('positive') }} von {{ getMaxPoints('', 'all', 'positive') }}</h5>
						</div>
						<div class="col-6">

						</div>
						<div class="col-6">
							<h5>Negativ: {{ getTotalPoints('negative') * -1 }} von {{ getMaxPoints('', 'all', 'negative') }}</h5>
						</div>
						<div class="col-6">

						</div>
						<div class="col-6">
							<h5>Berechnet: {{ getTotalPoints('positive') + getTotalPoints('negative') }}</h5>
						</div>
					</div>
					<div class="row contact-details-table" ref="rowElement">
						<div class="col-12 prinor-table">
							<b-table :data="contactDetails.details" ref="contactDetailsTable" :detailed="true" :custom-detail-row="true" detail-key="name" :show-detail-icon="true" :icon-pack="'fa'" :sticky-header="true">
								<b-table-column field="name" :label="$t('GENERAL.General.topic')" v-slot="props" :width="'20%'">
									<template>{{ props.row.name }}</template>
								</b-table-column>
								<b-table-column :label="$t('VIEWS.Report.Contact.question')" v-slot="props" :width="(pointsactive && pointsactive === true) ? '30%' : '40%'"></b-table-column>
								<b-table-column :label="$t('VIEWS.Report.Contact.answer')" v-slot="props" :width="(pointsactive && pointsactive === true) ? '30%' : '40%'"></b-table-column>
								<b-table-column v-if="pointsactive && pointsactive === true" :label="$t('VIEWS.Report.Contact.points')" v-slot="props" :width="'20%'">
									<div class="d-flex">
										<div v-if="getMaxPoints(props.row.id, 'topic', 'negative') !== 0">-{{ props.row.points_negative }} ({{ getMaxPoints(props.row.id, 'topic', 'negative') }}) / +{{ props.row.points_positive }} ({{ getMaxPoints(props.row.id, 'topic', 'positive') }})</div>
										<div v-if="getMaxPoints(props.row.id, 'topic', 'negative') === 0 && getMaxPoints(props.row.id, 'topic', 'positive') !== 0">{{ props.row.points_positive }} von {{ getMaxPoints(props.row.id, 'topic', 'positive') }}</div>
									</div>
								</b-table-column>

								<template slot="detail" slot-scope="props">
									<tr v-for="item in props.row.questions" :key="item.question + item.answer" :class="(item.question === null) ? 'border-top-none' : ''">
										<td></td>
										<td></td>
										<td>{{ item.question }}</td>
<!--										{{ (isSameQuestion(props.row.name, item.question, true)) ? item.question : '' }}-->
										<td>{{ item.answer }}</td>
										<td v-if="pointsactive && pointsactive === true">{{ item.points }}</td>
									</tr>
								</template>
							</b-table>
						</div>
					</div>

					<div class="prerender-points">
						<template v-for="item in contactDetails.details">
							<div :id="'points_' + item.id" :key="item.id" v-if="item.points_negative > 0 || item.points_positive > 0"></div>
						</template>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between modal-drag">
					<button type="button" v-on:click.prevent="hideContactModal" class="btn btn-outline-danger">
						{{ $t('GENERAL.Buttons.close') }}
					</button>
					<div>
						<button type="button" v-on:click.prevent="previousContact" class="btn btn-success mr-2" :disabled="currentContactIndex === 0"><i class="fas fa-angle-left"></i></button>
						<button type="button" v-on:click.prevent="nextContact" class="btn btn-success" :disabled="this.contactList.length - 1 <= currentContactIndex"><i class="fas fa-angle-right"></i></button>
					</div>
				</div>
			</div>
		</vue-final-modal>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default {
	name: 'ReportContact',
	props: {
		question: Object,
		pointsactive: Boolean,
		topics: Array,
		npsactive: Boolean,
	},
	data: function() {
		return {
			chartFilled: true,
			contactDetails: {
				email: '',
				firstname: '',
				lastname: '',
				phone: '',
				contact_answers: {},
				details: [
					{
						topic: '',
						question: '',
						answer: '',
					},
				],
			},
			contactColumns: [
				{
					field: 'topic',
					label: 'Bereich',
					searchable: true,
				},
				{
					field: 'question',
					label: 'Frage',
					searchable: true,
				},
				{
					field: 'answer',
					label: 'Antwort',
					searchable: true,
				},
			],
			tableColumns: [],
			currentContactIndex: 0,
			contactVisible: false,
			pointsVisible: false,
			contactList: [],
			subscriber: 0,
			lastQuestion: '',
			lastTopic: '',
			alreadyOutput: false,
		};
	},
	mounted: function() {
		if (this.$props.question.amount > 0) {
			this.calcAnswers();

			if ('contact' in this.$route.query && this.$route.query.contact != null) {
				if (this.$route.query.contact in this.$props.question.contact.contacts) {
					let row = this.$props.question.contact.contacts[this.$route.query.contact];
					if (row != null) {
						this.rowClicked(row);
					}
				}
				else {
					Swal.fire({
						title: this.$t('VIEWS.Report.Contact.contactInformation'),
						text: this.$t('VIEWS.Report.Contact.notFound'),
						icon: 'warning',
						confirmButtonText: this.$t('GENERAL.Buttons.cancel'),
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						heightAuto: false,
						allowOutsideClick: true,
						allowEscapeKey: true,
						allowEnterKey: true,
					});
				}
			}
		} else {
			this.chartFilled = false;
		}

		window.addEventListener('keyup', this.keyUpHandler);
	},
	computed: {
		modalHeight() {
			return window.screen.height * 0.9 - 100;
		},
	},
	methods: {
		rowClicked: function(row) {
			this.currentContactIndex = this.$refs.contactTable.newData.indexOf(row);
			this.contactDetails = row;

			if (this.question.contact.is_customer === true)
				this.contactDetails.is_customer = true;

			this.contactVisible = true;
		},
		toggle: function(row) {
			this.$refs.contactDetailsTable.toggleDetails(row);
		},
		nextContact: function() {
			if (this.contactList.length - 1 > this.currentContactIndex) {
				this.currentContactIndex++;
				this.contactDetails = this.$refs.contactTable.newData[this.currentContactIndex];
				if (this.question.contact.is_customer === true)
					this.contactDetails.is_customer = true;
			}
		},
		previousContact: function() {
			if (this.currentContactIndex !== 0) {
				this.currentContactIndex--;
				this.contactDetails = this.$refs.contactTable.newData[this.currentContactIndex];
				if (this.question.contact.is_customer === true)
					this.contactDetails.is_customer = true;
			}
		},
		keyUpHandler: function(e) {
			if (this.contactVisible === true) {
				if (e.keyCode === 37) {
					this.previousContact();
				} else if (e.keyCode === 39) {
					this.nextContact();
				}
			}
		},
		hideContactModal: function() {
			this.contactVisible = false;
		},
		setData() {
			this.calcAnswers();
		},
		calcAnswers: function() {
			this.contactList = Object.values(this.$props.question.contact.contacts);

			if (this.contactList.length === 0) {
				this.chartFilled = false;
			} else {

				if (this.question.contact.is_customer === false) {
					let i = 0;
					this.question.contact.fields.forEach(val => {
						if (i < 4) {
							this.tableColumns.push({
								field: val.id,
								label: val.name,
								searchable: true,
							});
						}

						i++;
					});
				}
				else {
					this.tableColumns.push({
						field: 'firstname',
						label: this.$t('GENERAL.General.person.firstName'),
						searchable: true,
					});
					this.tableColumns.push({
						field: 'lastname',
						label: this.$t('GENERAL.General.person.lastName'),
						searchable: true,
					});
					this.tableColumns.push({
						field: 'email',
						label: this.$t('GENERAL.General.person.email'),
						searchable: true,
					});
					this.tableColumns.push({
						field: 'phone',
						label: this.$t('GENERAL.General.person.phone'),
						searchable: true,
					});
				}

				this.chartFilled = true;
			}
		},
		getMaxPoints(id, type, direction) {
			if (type === 'all') {
				let amount = 0;

				this.topics.forEach(topic => {
					if (direction === 'positive')
						amount += topic.max_points_positive;
					else if (direction === 'negative')
						amount -= topic.max_points_negative;
				});

				return amount;
			}
			else if (type === 'topic') {
				let arr = this.topics.filter(value => { return value.id === id });

				if (arr != null && arr.length === 1) {
					if (direction === 'positive') {
						return arr[0].max_points_positive;
					}
					if (direction === 'negative') {
						return arr[0].max_points_negative;
					}
				}
			}
			else if (type === 'question') {
				this.topics.forEach(topic => {
					topic.questions.forEach(question => {
						if (question.id === id) {
							if (direction === 'positive') {
								return question.max_points_positive;
							}
							if (direction === 'negative') {
								return question.max_points_negative;
							}
						}
					});
				});
			}

			return '';
		},
		getTotalPoints: function(direction) {
			let amount = 0;

			if (this.contactDetails) {
				this.contactDetails.details.forEach(topic => {
					if (topic && topic.questions) {
						topic.questions.forEach(question => {
							if (direction === 'positive' && question.points > 0)
								amount += question.points;
							else if (direction === 'negative' && question.points < 0)
								amount += question.points;
						});
					}
				});
			}

			return amount;
		},
		isSameQuestion: function(topic, question, output) {
			if (topic !== this.lastTopic) {
				this.lastTopic = topic;
				this.alreadyOutput = false;
				return false;
			}

			if (question !== this.lastQuestion) {
				this.lastQuestion = question;
				this.alreadyOutput = false;
				return false;
			}
			else {
				if (output === true && this.alreadyOutput === false) {
					this.alreadyOutput = true;
					return true;
				}
				else if (output === false) {
					return true;
				}
			}

			return false;
		},
		downloadGraphs: function() {
			let charts = [];
			am4core.options.queue = false;
			// am4core.options.onlyShowOnViewport = false;
			let counter = 0;
			this.contactDetails.details.forEach(topic => {
				if (topic.points_negative > 0 || topic.points_positive > 0) {
					var chart = am4core.create(document.getElementById('points_' + topic.id), am4charts.XYChart);
					chart.padding(0, 0, 0, 0);
					chart.margin(0, 0, 0, 0);

					// chart.colors.list = [
					// 	am4core.color('#000000'),
					// 	am4core.color('#E51F33'),
					// 	am4core.color('#989898'),
					// 	am4core.color('#fab7be'),
					// ];

					let maxNegative = this.getMaxPoints(topic.id, 'topic', 'negative');
					let maxPositive = this.getMaxPoints(topic.id, 'topic', 'positive');

					if (maxNegative < 0)
						maxNegative = maxNegative * -1;

					let percentNegative = (topic.points_negative / maxNegative) * 100;
					let percentPositive = (topic.points_positive / maxPositive) * 100;

					// console.log('negative', topic.points_negative, maxNegative, percentNegative);
					// console.log('positive', topic.points_positive, maxPositive, percentPositive);

					var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
					categoryAxis.renderer.grid.template.location = 0;
					categoryAxis.dataFields.category = "id";
					categoryAxis.renderer.inversed = true;
					categoryAxis.renderer.grid.template.disabled = true;
					categoryAxis.renderer.labels.template.disabled = true;
					categoryAxis.renderer.cellStartLocation = 0;
					categoryAxis.renderer.cellEndLocation = 1;
					categoryAxis.renderer.axis.visible = false;

					var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
					valueAxis.renderer.grid.template.disabled = true;
					valueAxis.renderer.labels.template.disabled = true;
					// valueAxis.renderer.inside = true;
					valueAxis.renderer.axis.visible = false;
					valueAxis.renderer.grid.template.location = 0;
					valueAxis.min = 0;
					valueAxis.max = 100;

					var series = chart.series.push(new am4charts.ColumnSeries());
					series.dataFields.categoryY = "id";
					series.dataFields.valueX = "percent";
					// series.tooltipText = "{valueX.value}"
					series.columns.template.strokeOpacity = 0;
					series.columns.template.height = am4core.percent(100);
					series.stacked = true;

					var seriesMax = chart.series.push(new am4charts.ColumnSeries());
					seriesMax.dataFields.categoryY = "id";
					seriesMax.dataFields.valueX = "max";
					// seriesMax.tooltipText = "{valueX.value}"
					seriesMax.columns.template.strokeOpacity = 0;
					seriesMax.columns.template.height = am4core.percent(100);
					seriesMax.stacked = true;

					series.columns.template.adapter.add("fill", function(fill, target) {
						if (target.dataItem.categories.categoryY === "negative") {
							return am4core.color('#E51F33');
						}
						else {
							return am4core.color('#000000');
						}
					});

					seriesMax.columns.template.adapter.add("fill", function(fill, target){
						if (target.dataItem.categories.categoryY === "negative") {
							return am4core.color('#fab7be');
						}
						else {
							return am4core.color('#989898');
						}
					});

					chart.data = [
						{
							amount: topic.points_positive,
							percent: percentPositive,
							max: (100 - percentPositive),
							id: 'positive'
						},
						{
							amount: topic.points_negative,
							percent: percentNegative,
							max: (100 - percentNegative),
							id: 'negative'
						},
					];

					let labelNegative = chart.createChild(am4core.Label);
					labelNegative.text = topic.points_negative + " von " + maxNegative;
					labelNegative.fontSize = 28;
					labelNegative.fontWeight = "bold";
					labelNegative.align = "center";
					labelNegative.isMeasured = false;
					labelNegative.fill = am4core.color('#ffffff');
					labelNegative.x = am4core.percent(50);
					labelNegative.y = 133;
					labelNegative.horizontalCenter = "middle";

					let labelPositive = chart.createChild(am4core.Label);
					labelPositive.text = topic.points_positive + " von " + maxPositive;
					labelPositive.fontSize = 28;
					labelPositive.fontWeight = "bold";
					labelPositive.align = "center";
					labelPositive.isMeasured = false;
					labelPositive.fill = am4core.color('#ffffff');
					labelPositive.x = am4core.percent(50);
					labelPositive.y = 33;
					labelPositive.horizontalCenter = "middle";

					chart.exporting.filePrefix = this.contactDetails.contact_answers['5b08ade6-204c-4000-bd2b-eb78b090f321'] + this.contactDetails.contact_answers['66cc122c-9e7b-4e6f-a23c-70c429a469be'] + "_" + counter + "_" + topic.name.replace(/\s/g, "");
					counter++;
					charts.push(chart);
				}




				// let chart = am4core.create(document.getElementById('points_' + topic.id), am4charts.XYChart);
				//
				// chart.data = [
				// 	{
				// 		amount: topic.points_negative,
				// 		id: 'negative'
				// 	},
				// 	{
				// 		amount: topic.points_positive,
				// 		id: 'positive'
				// 	}
				// ];
				//
				// chart.colors.list = [
				// 	am4core.color('#005AA9'),
				// 	am4core.color('#0083CC'),
				// 	am4core.color('#009D81'),
				// 	am4core.color('#99C000'),
				// 	am4core.color('#C9D400'),
				// 	am4core.color('#FDCA00'),
				// 	am4core.color('#F5A300'),
				// 	am4core.color('#EC6500'),
				// 	am4core.color('#E6001A'),
				// 	am4core.color('#A600B4'),
				// 	am4core.color('#721085'),
				// ];
				//
				// let answerAxis = chart.xAxes.push(new am4charts.CategoryAxis());
				// answerAxis.tooltip.disabled = true;
				// answerAxis.dataFields.category = 'id';
				// answerAxis.renderer.labels.template.disabled = true;
				// answerAxis.renderer.axis.visible = false;
				// answerAxis.renderer.grid.template.location = 0;
				// answerAxis.renderer.grid.template.disabled = true;
				// answerAxis.padding(0, 0, 0, 0);
				//
				// let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				// valueAxis.tooltip.disabled = true;
				// valueAxis.renderer.grid.template.disabled = true;
				// valueAxis.renderer.labels.template.disabled = false;
				// valueAxis.baseValue = -1000;
				// valueAxis.padding(5, 0, 5, 0);
				// valueAxis.min = 0;
				// valueAxis.max =
				// 	Math.max.apply(
				// 		Math,
				// 		this.$props.question.answers.map(function(o) {
				// 			return o.amount;
				// 		})
				// 	) + 1;
				//
				// let series = chart.series.push(new am4charts.ColumnSeries());
				// series.dataFields.categoryX = 'id';
				// series.dataFields.valueY = 'amount';
				// series.calculatePercent = true;
				// series.tooltipText = '{valueY.percent}% ({valueY.value})';
				// series.tooltip.pointerOrientation = 'down';
				// series.columns.template.stroke = am4core.color('#fff');
				// series.columns.template.strokeOpacity = 1;
				//
				// series.columns.template.adapter.add('fill', function(fill, target) {
				// 	if (chart.colors.step === 2) {
				// 		return chart.colors.getIndex(target.dataItem.index * 2);
				// 	} else {
				// 		return chart.colors.getIndex(target.dataItem.index);
				// 	}
				// });
				//
				// chart.cursor = new am4charts.XYCursor();
				// chart.seriesContainer.align = 'left';
				// chart.seriesContainer.contentAlign = 'left';
				// chart.seriesContainer.paddingLeft = 0;
				// chart.seriesContainer.marginLeft = 0;
				// chart.numberFormatter.numberFormat = '#,###.##';
				//
				// charts.push(chart);
			});
			let test = 0;
			charts.forEach(chart => {
				if (test === 0)
					chart.exporting.export("png");

				test++;
			});

			am4core.options.queue = true;
			// am4core.options.onlyShowOnViewport = true;
		},
	},
};
</script>

<style lang="scss">
.prinor-table {
	@import '~bulma';
	@import '~buefy/src/scss/buefy';
}
</style>
<style scoped>
.empty-chart {
	top: calc(50% - 7.5rem);
}

.empty-chart > i {
	font-size: 15rem;
	color: #e0e0e0;
}

.empty-chart-text {
	top: calc(50% - 1.5rem);
	font-size: 3rem;
	text-align: center;
}

.card-body {
	min-height: 20rem;
}

.card-label > div > span {
	font-size: 2rem;
}

.card-label > div > i {
	font-size: 3rem;
}
</style>

<style scoped>
::v-deep .modal-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
::v-deep .modal-content {
	position: relative;
	display: flex;
	flex-direction: column;
	max-height: 90%;
	/*margin: 0 1rem;*/
	/*padding: 1rem;*/
	border: 1px solid #e2e8f0;
	border-radius: 0.25rem;
	background: #fff;
	width: 50vw;
}
::v-deep .contact-details-table .b-table,
::v-deep .contact-details-table .table-wrapper {
	height: 100% !important;
}
.modal__title {
	margin: 0 2rem 0 0;
	font-size: 1.5rem;
	font-weight: 700;
}
.modal__content {
	flex-grow: 1;
	overflow-y: auto;
}
.modal__close {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}
.contact-details-table .prinor-table tr td {
	border: none !important;
}
.contact-details-table .prinor-table tr {
	border-top: 1px solid #dbdbdb !important;
	border-collapse: collapse !important;
}
::v-deep .contact-details-table .b-table .table {
	border-collapse: collapse !important;
}
.contact-details-table tr.border-top-none {
	border-top: none !important;
}

.prerender-points > div {
	width: 500px;
	height: 200px;
}

.prerender-points {
	position: fixed;
	top: -10000px;
	width: 500px;
	height: auto;
}
</style>