<template>
	<div class="col-xl-4 daily-statistic">
		<div class="card card-custom gutter-b card-stretch">
			<div class="card-header border-0 pt-5">
				<div class="card-title">
					<div class="card-label">
						<div class="font-weight-bolder">{{ $t('VIEWS.Report.Daily.title') }}</div>
						<div class="font-size-sm text-muted mt-2"></div>
					</div>
				</div>
				<div class="card-toolbar">
					<a href="#" class="btn btn-sm btn-icon" v-on:click.prevent="() => { $modal.show('statistic-details' + _uid); }">
						<i class="fas fa-table"></i>
					</a>
				</div>
			</div>
			<div class="card-body d-flex flex-column px-0">
				<div class="hello mb-3" ref="chartdiv"></div>
				<div class="flex-grow-1 card-spacer-x">
					<div class="d-flex align-items-center justify-content-between mb-10">
						<div class="d-flex align-items-center mr-2">
							<div>
								<a href="#" class="font-size-h6 text-dark text-hover-primary font-weight-bolder">{{ $t('VIEWS.Report.Daily.subscriberTotal') }}</a>
								<div class="font-size-sm text-muted font-weight-bold mt-1"></div>
							</div>
						</div>
						<div class="label label-light label-inline font-weight-bold text-dark py-4 px-3 font-size-base">
							{{ data.subscriber }}
						</div>
					</div>
				</div>
				<div class="flex-grow-1 card-spacer-x">
					<div class="d-flex align-items-center justify-content-between mb-10">
						<div class="d-flex align-items-center mr-2">
							<div>
								<a href="#" class="font-size-h6 text-dark text-hover-primary font-weight-bolder">{{ $t('VIEWS.Report.Daily.views') }}</a>
								<div class="font-size-sm text-muted font-weight-bold mt-1"></div>
							</div>
						</div>
						<div class="label label-light label-inline font-weight-bold text-dark py-4 px-3 font-size-base">
							{{ data.views }}
						</div>
					</div>
				</div>
				<div class="flex-grow-1 card-spacer-x">
					<div class="d-flex align-items-center justify-content-between mb-10">
						<div class="d-flex align-items-center mr-2">
							<div>
								<a href="#" class="font-size-h6 text-dark text-hover-primary font-weight-bolder">{{ $t('VIEWS.Report.Daily.daysOnline') }}</a>
								<div class="font-size-sm font-weight-bold mt-1 text-dark">{{ new Date(data.started_at).toLocaleString() }}</div>
							</div>
						</div>
						<div class="label label-light label-inline font-weight-bold text-dark py-4 px-3 font-size-base">{{ data.days_online }} {{ $t('VIEWS.Report.Daily.days') }}</div>
					</div>
				</div>
			</div>
		</div>
		<modal :name="'statistic-details' + _uid" :width="1200" :height="modalHeight" :shiftY="0.1" :adaptive="true" scrollable>
			<div class="card card-custom" style="height: inherit;">
				<div class="card-header card-header-tabs-line">
					<div class="card-title w-100 d-flex justify-content-between">
						<h3 class="card-label">{{ $t('VIEWS.Report.Daily.table.allData') }}</h3>
					</div>
				</div>
				<div class="card-body">
					<div class="row h-100">
						<div class="col-12 h-100 prinor-table">
							<b-table :data="data.all_data" table-class="h-100" default-sort="date" :default-sort-direction="'desc'" :icon-pack="'fa'" :sticky-header="true" :detailed="detailsEnabled()" :custom-detail-row="detailsEnabled()" detail-key="date" :show-detail-icon="detailsEnabled()">
								<b-table-column field="date" :label="$t('GENERAL.General.time.date')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
									<template>
										<span class="truncate font-weight-bolder">{{ new Date(props.row.date).toLocaleString() }}</span>
									</template>
								</b-table-column>
								<b-table-column field="views" :label="$t('VIEWS.Report.Daily.table.viewsTotal')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
									<template>
										<span class="truncate font-weight-bolder">{{ props.row.views }}</span>
									</template>
								</b-table-column>
								<b-table-column field="subscribers" :label="$t('VIEWS.Report.Daily.table.subscribersTotal')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
									<template>
										<span class="truncate font-weight-bolder">{{ props.row.subscribers }}</span>
									</template>
								</b-table-column>
								<b-table-column field="nps" :label="$t('VIEWS.Report.Daily.table.averageNps')" v-slot="props" :sortable="true" :searchable="true" cell-class="text-valign">
									<template>
										<span class="truncate font-weight-bolder">{{ props.row.nps }}</span>
									</template>
								</b-table-column>

								<template slot="detail" slot-scope="props">
									<tr v-for="item in detailsItems(props.row)" :key="item.date">
										<td></td>
										<td class="text-right font-italic">{{ item.name }}</td>
										<td>{{ item.views }}</td>
										<td>{{ item.subscribers }}</td>
										<td>{{ item.nps }}</td>
									</tr>
								</template>
							</b-table>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button type="button" v-on:click.prevent="() => { $modal.hide('statistic-details' + _uid); }" class="btn btn-outline-danger">
						{{ $t('GENERAL.Buttons.close') }}
					</button>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
// am4charts
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
// import am4themes_animated from "@amcharts/amcharts4/themes/animated"
// am4core.useTheme(am4themes_animated);

export default {
	name: 'DailyStatistic',
	props: {
		data: Object,
	},
	data() {
		return {
			chart: null,
		};
	},
	computed: {
		modalHeight() {
			return window.screen.height * 0.9 - 100;
		},
	},
	methods: {
		detailsEnabled: function() {
			if (this.data.all_companies.length <= 1) {
				return false;
			}

			return true;
		},
		detailsItems: function(row) {
			let arr = [];

			this.data.all_companies.forEach(company => {
				let rowData = {
					name: company,
				};

				if ('nps_' + company in row) {
					rowData.nps = row['nps_' + company];
				}
				if ('views_' + company in row) {
					rowData.views = row['views_' + company];
				}
				if ('subscribers_' + company in row) {
					rowData.subscribers = row['subscribers_' + company];
				}

				arr.push(rowData);
			});

			return arr;
		},
	},
	mounted() {
		am4core.addLicense('CH246099470');
		am4core.options.queue = true;
		// am4core.options.onlyShowOnViewport = true;

		let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

		chart.padding(0, 0, 0, 0);
		chart.margin(0, 0, 0, 0);

		let chartData = [];
		if (this.$props.data.view_chart != null) {
			this.$props.data.view_chart.forEach(vc => {
				vc.date = new Date(vc.date);

				if (vc.date >= new Date().addDays(-31)) {
					chartData.push(vc);
				}
			});
		}

		chart.data = chartData;

		let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.tooltip.disabled = true;
		dateAxis.renderer.labels.template.disabled = false;
		dateAxis.renderer.axis.visible = false;
		dateAxis.renderer.grid.template.location = 0;
		dateAxis.renderer.grid.template.disabled = true;
		dateAxis.startLocation = 0.5;
		dateAxis.endLocation = 0.51;
		dateAxis.padding(0, 0, 0, 0);

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.tooltip.disabled = true;
		valueAxis.renderer.grid.template.disabled = true;
		valueAxis.renderer.labels.template.disabled = true;
		valueAxis.padding(0, 0, 0, 0);
		valueAxis.baseValue = -1000;
		valueAxis.startLocation = 1;
		valueAxis.endLocation = 0;

		let series = chart.series.push(new am4charts.LineSeries());
		series.dataFields.dateX = 'date';
		series.dataFields.valueY = 'view';
		series.tensionX = 0.77;
		series.baseAxis = dateAxis;
		series.legendSettings.visible = false;
		series.fill = am4core.color('#91B1C0');
		series.fillOpacity = 0.3;
		series.stroke = am4core.color('#91B1C0');
		series.tooltipText = this.$t('VIEWS.Report.Daily.views') + ': {valueY.value} \n{dateX}';
		series.tooltip.pointerOrientation = 'down';

		let seriesSubscriber = chart.series.push(new am4charts.LineSeries());
		seriesSubscriber.dataFields.dateX = 'date';
		seriesSubscriber.dataFields.valueY = 'subscribers';
		seriesSubscriber.tensionX = 0.77;
		seriesSubscriber.baseAxis = dateAxis;
		seriesSubscriber.legendSettings.visible = false;
		seriesSubscriber.fill = am4core.color('#33b782');
		seriesSubscriber.fillOpacity = 0.8;
		seriesSubscriber.stroke = am4core.color('#359971');
		seriesSubscriber.tooltipText = this.$t('VIEWS.Report.Daily.subscribers') + ': {valueY.value} \n{dateX}';
		seriesSubscriber.tooltip.pointerOrientation = 'down';

		chart.cursor = new am4charts.XYCursor();
		chart.seriesContainer.align = 'left';
		chart.seriesContainer.contentAlign = 'left';
		chart.seriesContainer.paddingLeft = 0;
		chart.seriesContainer.marginLeft = 0;
		chart.dateFormatter.dateFormat = 'd.MM.yy';

		this.chart = chart;
	},
	beforeDestroy() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
};
</script>

<style scoped>
.hello {
	width: 100%;
	height: 150px;
}
</style>
